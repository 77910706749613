import axios from 'axios';
import cookies from '../cookies';

const baseService = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

baseService.removeAuthToken = () => {
  cookies.remove('authToken');
  cookies.remove('isLoggedIn');
  cookies.remove('role');
  cookies.remove('firstName');
  cookies.remove('lastName');
  cookies.remove('userID');
  baseService.defaults.headers.common.Authorization = '';
  let pathname = window.location.pathname;
  if(['/onsite', '/media', '/shared-with-me', '/youtubeDl', '/users'].includes(pathname)) {
    window.location = `/?redirect=${pathname}/${window.location.search}`;
  } else {
    window.location = '/';
  }
};

baseService.interceptors.request.use(config => {
  if(config.url !== '/auth/login') {
    let token = cookies.get('authToken');
    config.headers = token ? { ...config.headers, Authorization: `Bearer ${token}` } : config.headers;
    return config;
  } else {
    return config
  }
});

baseService.interceptors.response.use(config => config, (error) => {
  if (error.response.status === 401 || error.response.status === 403) {
    baseService.removeAuthToken();
  }
  return Promise.reject(error.response);
});

export default baseService;

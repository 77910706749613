import React from "react";
import {Form, Input, Checkbox, Progress} from "semantic-ui-react";

import CustomModal from "../CustomModal";
import Snackbar from "../Snackbar";
import baseService from '../../services/baseService';

class AddFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        is_public: true,
        files: [],
      },
      fileUploadLength: 0,
      fileUploading: [],
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      },
    }
  }

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  fileChange = (event) => {
    const eventFiles = event.target.files;
    if (eventFiles && eventFiles.length > 0) {
      let files = [];
      for(let file of eventFiles) {
        if((file.size / 1024 / 1024) > 500) {
          this.setState({
            payload: {
              is_public: true,
              files: [],
            },
            fileUploadLength: 0,
            fileUploading: [],
            snackbar: {
              open: true,
              message: 'All file size must be 500 MB or less!',
              success: false,
              error: true
            }
          });
          return this.props.onClose();
        } else {
          files.push(file);
        }
      }
      this.setState(state => ({payload: {...state.payload, files}, fileUploadLength: files.length}));
    }
  }

  uploadFile = (file, folderID, is_public, index) => new Promise(resolve => {
    const formData = new FormData();
    formData.append('files', file);
    let {fileUploading} = this.state;
    fileUploading.push({name: file.name, percentage: 0, status: 'uploading'});
    this.setState({fileUploading});
    baseService.post('/file/upload', formData, {
      onUploadProgress: (progressEvent) => {
        let {fileUploading} = this.state;
        fileUploading[index].percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total) - 5;
        this.setState({fileUploading});
      },
    })
      .then((response) => {
        let dataToUpload = {
          access_url: response.data.access_url,
          thumbnail: response.data.thumbnail,
          folder_id: folderID,
          name: file.name,
          type: file.type,
          is_public,
        }
        const {addFile} = this.props;
        addFile(dataToUpload).then(() => {
          let {fileUploading} = this.state;
          fileUploading[index].percentage = 100;
          fileUploading[index].status = 'success';
          this.setState({fileUploading});
        })
        .catch(() => {
          let {fileUploading} = this.state;
          fileUploading[index].status = 'failed';
          this.setState({fileUploading});
        })
        .finally(() => resolve())
      })
      .catch(() => {
        let {fileUploading} = this.state;
        fileUploading[index].status = 'failed';
        this.setState({fileUploading});
        return resolve();
      });
  })

  onSubmit = async () => {
    const {folderID, onClose} = this.props;
    const {payload} = this.state;
    if(!payload.files.length) {
      this.setState({
        snackbar: {
          open: true,
          message: 'Please select a file!',
          success: false,
          error: true
        }
      });
      return onClose();
    }
    for(let i = 0, len = payload.files.length; i < len; i++) {
      await this.uploadFile(payload.files[i], folderID, payload.is_public, i);
    }
    this.setState({
      payload: {
        is_public: true,
        files: [],
      },
      fileUploadLength: 0,
      fileUploading: [],
      snackbar: {
        open: true,
        message: 'File Uploaded!',
        success: true,
        error: false
      },
    });
    return onClose();
  }

  render() {
    const {open, onClose, fileLoading} = this.props;
    const { snackbar, payload, fileUploading, fileUploadLength } = this.state;
    let fileUploadingLength = fileUploading.length;
    let lastFileUpload = fileUploading[fileUploadingLength - 1];
    let failCount = fileUploading.filter(x => x.status === 'failed').length;
    return <>
      <CustomModal
        open={open}
        onClose={() => {
          if(fileUploadingLength === 0 || fileUploading.filter(x => x.status === 'uploading').length === 0) {
            this.setState({
              payload: {
                is_public: true,
                files: [],
              },
              fileUploadLength: 0,
              fileUploading: [],
            });
            onClose();
          }
        }}
        footer={true}
        title='Add File'
        onSubmit={this.onSubmit}
        submitLoader={fileLoading}
      >
        <Form>
          <Form.Field>
            <label>File</label>
            <Input
              type='file'
              id='file'
              multiple
              onChange={this.fileChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Is Public</label>
            <Checkbox
              id='chk-is-public'
              toggle
              checked={payload.is_public}
              onChange={(e, { checked }) => this.setState(state => ({ ...state, payload: { ...state.payload, is_public: checked }}))}
            />
          </Form.Field>
          {
            fileUploadingLength > 0 &&
            <><label>Uploading : {fileUploadingLength}/{fileUploadLength} Failed : {failCount}</label>
            <br/><label>{lastFileUpload.name}</label>
            <Progress percent={lastFileUpload.percentage} success={lastFileUpload.status==='success'} indicating progress /></>
          }
        </Form>
      </CustomModal>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
        success={snackbar.success}
        onClose={this.resetSnackbar}
      />
    </>
  }
}

export default AddFile;

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment
} from 'semantic-ui-react';

import {login} from '../../actions/authActions';
import './login.css';
import {Link} from "react-router-dom";

const style = {
  error: {
    color: 'red',
    fontWeight: 'bold'
  },
  img_logo: {
    height: '1.5em',
    width: '5.5em'
  }
};

export class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      startLogIn: false
    };
  }

  componentDidMount() {
    if (this.props.auth.isLoggedIn) {
      const redirect = new URLSearchParams(this.props.location.search).get("redirect");
      this.props.history.push(redirect ? redirect : '/home');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isLoggedIn) {
      const redirect = new URLSearchParams(this.props.location.search).get("redirect");
      this.props.history.push(redirect ? redirect : '/home');
    }
    if (!nextProps.auth.isLoggedIn && nextProps.auth.message) {
      this.setState({failedMessage: nextProps.auth.message});
    }
    this.setState({
      startLogIn: false
    });
  }

  handleState = (e) => {
    const {name, value} = e.target;
    this.checkValidation(name, value);
    this.setState({
      [name]: value,
      failedMessage: ''
    });
  };

  submitClick = () => {
    const {
      username, password
    } = this.state;
    if (this.checkValidation('username', username || '') && this.checkValidation('password', password || '')) {
      const {loginUser} = this.props;
      this.setState({
        startLogIn: true
      });
      loginUser({username, password});
    }
  };

  checkValidation = (name, value) => {
    const {error} = this.state;
    if (name === 'username') {
      if (value.length === 0) {
        error.username = 'Enter Username !';
      } else if (value.length < 4) {
        error.username = 'Username must be more than 3 chars !';
      } else {
        delete error.username;
      }
    }
    if (name === 'password') {
      if (value.length === 0) {
        error.password = 'Enter Password !';
      } else if (value.length < 6) {
        error.password = 'Password must be more than 5 chars !';
      } else {
        delete error.password;
      }
    }
    this.setState({
      error,
    });
    return (!(error.username || error.password));
  };

  render() {
    const {
      error, failedMessage, startLogIn
    } = this.state;

    return (
      <div className="login-form">
        <Grid textAlign="center" style={{height: '100%'}} verticalAlign="middle">
          <Grid.Column className="main-wrap">
            {
              failedMessage && <Message negative>{failedMessage} </Message>
            }
            <Segment stacked>
              <Header as="h2" color="teal" textAlign="center">
                <Image src="/logo.svg" style={style.img_logo}/> Log-in to your account
              </Header>
              <Form size="large">
                <Segment>
                  <Form.Input
                    id="input-username"
                    fluid
                    icon="user"
                    name="username"
                    error={!!error.username}
                    iconPosition="left"
                    placeholder="Username"
                    onChange={this.handleState}
                  />
                  <p style={style.error}>{error.username}</p>
                  <Form.Input
                    fluid
                    id="input-password"
                    icon="lock"
                    error={!!error.password}
                    name="password"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    onChange={this.handleState}
                  />
                  <p style={style.error}>{error.password}</p>
                  <Button
                    color="teal"
                    fluid
                    size="large"
                    loading={startLogIn}
                    onClick={this.submitClick}
                  >
                    Login
                  </Button>
                </Segment>
                <Segment>
                  <Link to="/media">Public Media</Link>
                </Segment>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, {loginUser: login})(LoginForm);

import React, { Component } from "react";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Form,
  Input,
  Select,
  Loader,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  getAllElements,
  addElement,
  deleteElement,
  downloadElement,
  editElement,
} from "../../actions/youtubeDlActions";
import { listUser } from "../../actions/userActions";
import MyModal, { ConfirmModal } from "../CustomModal";
import AddYoutubeDlPage from "./addYoutubeDl";
import EditYoutubeDlPage from "./editYoutubeDl";
import MessageBar from "../Snackbar";
import "./index.css";

const PaginationComponent = ({ totalPages, activePage, handlePageChange }) => (
  <Pagination
    totalPages={totalPages}
    activePage={activePage}
    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
    prevItem={{ content: <Icon name="angle left" />, icon: true }}
    nextItem={{ content: <Icon name="angle right" />, icon: true }}
    onPageChange={handlePageChange}
  />
);

class YoutubeTrimmer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isEditModalOpen: false,
      page: 1,
      size: 10,
      searchText: "",
      showMsg: false,
      message: "",
      response: {},
      sortedField: "created",
      sortDirection: "desc",
      userID: this.props.authReducer.userID,
      deletedElement: {},
      doConfirm: false,
      role: this.props.authReducer.role,
      editData: {},
    };
  }

  componentDidMount() {
    this.fetchElements();
    this.fetchUserList();
  }

  fetchElements = () => {
    const { fetchYoutubeDlAction } = this.props;
    const { page, size, searchText, sortDirection, sortedField, userID } =
      this.state;
    fetchYoutubeDlAction(
      userID,
      page,
      size,
      searchText,
      sortDirection,
      sortedField
    );
  };

  fetchUserList = () => {
    const { listUsers } = this.props;
    listUsers();
  };

  showStatus = (msg, res) => {
    this.setState({
      showMsg: true,
      message: msg,
      response: res,
    });
  };

  handleChange = (e, { value }) => {
    const { page, size } = this.state;
    const diff = Math.ceil(value / size);
    if (size !== value) {
      this.setState(
        {
          size: value,
          page: Math.ceil(page / diff),
        },
        () => this.fetchElements()
      );
    }
  };

  handleAdd = (element) => {
    this.setState({
      youtubeDlData: { ...element },
      isModalOpen: true,
    });
  };

  handleEdit = (element) => {
    this.setState({
      editData: { ...element },
      isEditModalOpen: true,
    });
  };

  handlePageChange = (e, { activePage }) => {
    this.setState(
      {
        page: activePage,
      },
      () => () => this.fetchElements()
    );
  };

  handleSearchTextChange = (e) => {
    const { value } = e.target;
    this.setState({
      searchText: value,
    });
  };

  showMessage = () => {
    const {
      message = "",
      response: { success = false, error = false },
      showMsg,
    } = this.state;
    if (success) {
      return (
        <MessageBar
          open={showMsg}
          message={message}
          success
          onClose={() =>
            setTimeout(() => {
              this.setState({ showMsg: false, response: {} });
            }, 1500)
          }
        />
      );
    } else if (error) {
      return (
        <MessageBar
          open={showMsg}
          message={message}
          error
          onClose={() =>
            setTimeout(() => {
              this.setState({ showMsg: false, response: {} });
            }, 1500)
          }
        />
      );
    }
    return null;
  };

  handleSort = (columnName) => {
    let { sortedField, sortDirection } = this.state;
    if (sortedField === columnName) {
      if (sortDirection === "asc") {
        sortDirection = "desc";
      } else {
        sortDirection = "asc";
      }
    } else {
      sortedField = columnName;
      sortDirection = "asc";
    }
    this.setState(
      {
        sortedField,
        sortDirection,
      },
      () => {
        this.fetchElements();
      }
    );
  };

  handleDeleteClick = (element) => {
    this.setState({
      doConfirm: true,
      deletedElement: element,
    });
  };

  handleConfirm = (id) => {
    const { deleteElementAction } = this.props;
    if (id) {
      this.setState({
        loadingBTN: true,
      });
      let message = "";
      const response = {
        success: false,
        error: false,
      };
      deleteElementAction(id)
        .then(() => {
          message = "Element Deleted";
          response.success = true;
          this.showStatus(message, response);
          this.setState({
            loadingBTN: false,
          });
          this.fetchElements();
        })
        .catch(() => {
          message = "Element Not Deleted";
          response.error = true;
          this.showStatus(message, response);
          this.setState({
            loadingBTN: false,
          });
        });
    }
    this.setState({
      doConfirm: false,
    });
  };

  handleCancel = () => {
    this.setState({
      doConfirm: false,
    });
  };
  render() {
    const {
      youtubeDlReducer: { data, loading, total },
      addYoutubeDlData,
      editYoutubeDlData,
      userReducer: { data: users },
      downloadYoutubeFile,
    } = this.props;
    const {
      page,
      searchText,
      isModalOpen,
      isEditModalOpen,
      youtubeDlData,
      size,
      sortedField,
      sortDirection,
      doConfirm,
      deletedElement,
      loadingBTN,
      userID,
      role,
      editData,
    } = this.state;
    const showMsg = this.showMessage();

    return (
      <div className="youtube-container">
        {showMsg}
        <div className="content-block">
          <div className="user-body">
            <Form.Field
              id="search"
              control={Input}
              name="searchText"
              onChange={this.handleSearchTextChange}
              value={searchText}
              placeholder="Enter search text and press enter"
              style={{ width: "100%" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) this.fetchElements();
              }}
            />
            <Button primary size="medium" onClick={this.fetchElements}>
              Search
            </Button>
          </div>
          <div>
            <Button primary size="medium" onClick={this.handleAdd}>
              Add Element
            </Button>
          </div>
        </div>
        <div className="page-content">
          <div className="user-pagination">
            <PaginationComponent
              totalPages={Math.ceil(total / size)}
              activePage={page}
              handlePageChange={this.handlePageChange}
            />
            <span className="records-count">{total} Element found !</span>
          </div>
          <div className="user-dropdown-box">
            {role === "admin" && (
              <div className="user-box">
                <span className="user-head">Users : </span>
                <Select
                  options={[
                    { text: "All Users", value: "all" },
                    ...users.map((user) => ({
                      text: user.username,
                      value: user.employee_key,
                    })),
                  ]}
                  onChange={(e, { value }) => {
                    this.setState({ userID: value }, () =>
                      this.fetchElements()
                    );
                  }}
                  value={userID}
                  compact
                  search
                  className="role-dropdown"
                />
              </div>
            )}
            <div className="pagination">
              <span>Records per page : </span>
              <Select
                placeholder="Records Per Page"
                options={[
                  { value: 10, text: "10" },
                  { value: 20, text: "20" },
                  { value: 50, text: "50" },
                  { value: 100, text: "100" },
                ]}
                onChange={this.handleChange}
                defaultValue={10}
                compact
                className="role-dropdown"
              />
            </div>
          </div>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">
                Name
                <Button
                  className="sort-icon"
                  float="right"
                  icon={
                    sortedField === "name"
                      ? sortDirection === "asc"
                        ? "sort up"
                        : "sort down"
                      : "sort"
                  }
                  size="tiny"
                  onClick={() => {
                    this.handleSort("name");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Status
                <Button
                  className="sort-icon"
                  float="right"
                  icon={
                    sortedField === "status"
                      ? sortDirection === "asc"
                        ? "sort up"
                        : "sort down"
                      : "sort"
                  }
                  size="tiny"
                  onClick={() => {
                    this.handleSort("status");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Source</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan="6">
                  <Loader active inline="centered" />
                </Table.Cell>
              </Table.Row>
            ) : data.length ? (
              data.map((element) => (
                <Table.Row key={element.id}>
                  <Table.Cell>{element.name || "-"}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {element.status === "downloaded" ? (
                      <Icon name="check" color="green" title="Downloaded" />
                    ) : element.status === "pending_download" ? (
                      <Icon
                        name="clock"
                        color="blue"
                        title="Download Pending"
                      />
                    ) : element.status === "in_progress" ? (
                      <Icon
                        name="hourglass half"
                        color="blue"
                        title="In Progress"
                      />
                    ) : (
                      <Icon
                        name="exclamation circle"
                        color="red"
                        title="Failed"
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>{element.source || "-"}</Table.Cell>
                  <Table.Cell textAlign="center" width="three">
                    <Button
                      className="label-text"
                      title="Edit Element"
                      onClick={() => {
                        this.handleEdit(element);
                      }}
                    >
                      <Icon name="edit" color="blue" />
                    </Button>
                    {element.status === "downloaded" && (
                      <>
                        <Button
                          className="label-text"
                          title="Open"
                          onClick={() => window.open(element.uri, "_blank")}
                        >
                          <Icon name="arrow up" color="blue" />
                        </Button>
                        <Button
                          className="label-text"
                          title="Download File"
                          loading={element.downloading}
                          disabled={element.downloading}
                          onClick={() =>
                            downloadYoutubeFile(element.id, element.uri)
                          }
                        >
                          <Icon name="download" color="blue" />
                        </Button>
                      </>
                    )}
                    <Button
                      className="label-text"
                      title="Delete Element"
                      onClick={() => {
                        this.handleDeleteClick(element);
                      }}
                    >
                      <Icon name="trash" color="red" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan="5">
                  No Records Found
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <PaginationComponent
          totalPages={Math.ceil(total / size)}
          activePage={page}
          handlePageChange={this.handlePageChange}
        />
        <MyModal
          open={isModalOpen}
          title={"Add Element"}
          onClose={() =>
            this.setState({ isModalOpen: false, youtubeDlData: {} })
          }
        >
          <AddYoutubeDlPage
            open={isModalOpen}
            closeModal={() =>
              this.setState({
                isModalOpen: false,
                youtubeDlData: {},
              })
            }
            addYoutubeDlData={addYoutubeDlData}
            fetchElements={this.fetchElements}
            eData={youtubeDlData}
            showStatus={this.showStatus}
          />
        </MyModal>
        <MyModal
          open={isEditModalOpen}
          title={"Edit Element"}
          onClose={() =>
            this.setState({ isEditModalOpen: false, editData: {} })
          }
        >
          <EditYoutubeDlPage
            open={isEditModalOpen}
            closeModal={() =>
              this.setState({
                isEditModalOpen: false,
                editData: {},
              })
            }
            editYoutubeDlData={editYoutubeDlData}
            eData={editData}
            showStatus={this.showStatus}
          />
        </MyModal>
        <ConfirmModal
          open={doConfirm}
          loading={loadingBTN}
          onCancelClick={this.handleCancel}
          onYesClick={() => {
            this.handleConfirm(deletedElement.id);
          }}
          header={"Delete Element"}
          primaryKey={`Element ${deletedElement && deletedElement.name}`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  youtubeDlReducer: state.youtubeDlReducer,
  authReducer: state.authReducer,
  userReducer: state.userReducer,
});

export default connect(mapStateToProps, {
  fetchYoutubeDlAction: getAllElements,
  addYoutubeDlData: addElement,
  deleteElementAction: deleteElement,
  listUsers: listUser,
  downloadYoutubeFile: downloadElement,
  editYoutubeDlData: editElement,
})(YoutubeTrimmer);

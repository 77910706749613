import React from "react";
import CustomModal from "../CustomModal";
import {Form} from "semantic-ui-react";

export default function DownloadAs(props) {
  const { open, onClose, fileName, fileURL } = props;
  let file = fileURL && fileURL.includes('=') && fileURL.split('=')[1];
  return <>
    <CustomModal
      open={open}
      onClose={onClose}
      title='Download As'
      subTitle='To choose your download destination, right-click the link below, then choose "Save link as..."'
    >
      <Form className="download-as">
        <Form.Field>
          <a href={`https://d2a39xd6ecmbf2.cloudfront.net/drive/${file}`} rel="noreferrer" download={fileName} target="_blank">{ fileName }</a>
        </Form.Field>
      </Form>
    </CustomModal>
  </>
};

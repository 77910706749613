import baseService from "./baseService";

export const getElements = (
  employee_key,
  page,
  size,
  searchText,
  sortDirection,
  sortedField
) =>
  baseService.get(`/youtubeDl`, {
    headers: {
      user: employee_key,
      page,
      size,
      field: sortedField,
      sort: sortDirection,
      search: searchText,
    },
  });

export const deleteElement = (id) => baseService.delete(`/youtubeDl/${id}`);

export const addElement = (payload) => baseService.post("/youtubeDl", payload);

export const getElementByID = (id) => baseService.get(`/youtubeDl/${id}`);

export const getPublicElements = () => baseService.get("/youtubeDl/public");

export const editElement = (payload) => baseService.put("/youtubeDl", payload);

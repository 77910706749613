import React, { Component } from "react";
import { Form, Button, Input, Checkbox } from "semantic-ui-react";

class EditYoutubeDl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editElementData: {},
      error: {},
      loadingBTN: false,
    };
  }

  componentDidMount() {
    const { eData } = this.props;
    this.setState({
      editElementData: {
        ...eData,
      },
    });
  }

  handleInputChange = (e, data) => {
    const { name, value } = e.target;
    const { editElementData } = this.state;
    editElementData[name] = value;
    this.setState({ editElementData });
  };

  handleEdit = () => {
    const { editYoutubeDlData, closeModal, showStatus } = this.props;
    let { editElementData } = this.state;
    let message = "";
    let response = {
      success: false,
      error: false,
    };
    if (this.validateFields()) {
      this.setState({
        loadingBTN: true,
      });

      editYoutubeDlData(editElementData)
        .then((res) => {
          message = "Element Updated";
          response.success = true;
          closeModal();
          showStatus(message, response);
          this.setState({
            editElementData: {},
            loadingBTN: false,
          });
        })
        .catch((err) => {
          if (
            err.data &&
            err.data.error &&
            err.data.error.code === "ER_DUP_ENTRY"
          ) {
            this.setState({
              error: {
                name: { isError: true, message: "Name Already Exists!" },
              },
              loadingBTN: false,
            });
          } else {
            message = (err.data && err.data.message) || "Element not Updated !";
            response.error = true;
            closeModal();
            showStatus(message, response);
            this.setState({
              loadingBTN: false,
            });
          }
        });
    }
  };

  validateFields = () => {
    let {
      editElementData: { name },
      error,
    } = this.state;
    if (!name) {
      error.name = { isError: true, message: "Enter Name" };
    } else {
      delete error.name;
    }

    this.setState({
      error,
    });
    return !Object.keys(error).length > 0;
  };

  render() {
    const { editElementData, error, loadingBTN } = this.state;
    return (
      <Form>
        <Form.Field
          name="name"
          value={editElementData.name}
          label="Name"
          type="text"
          control={Input}
          placeholder="Name"
          error={
            error.name &&
            error.name.isError &&
            error.name.message === "Name Already Exists!"
              ? {
                  content: error.name.message,
                  pointing: "below",
                }
              : error.name && error.name.isError
          }
          onChange={(e, data) => this.handleInputChange(e, data)}
        ></Form.Field>
        <Form.Field>
          <label>Is Public</label>
          <Checkbox
            id="chk-is-public"
            toggle
            checked={editElementData.is_public}
            onChange={(e, { checked }) =>
              this.setState((state) => ({
                editElementData: {
                  ...state.editElementData,
                  is_public: checked,
                },
              }))
            }
          />
        </Form.Field>
        <Form.Field
          control={Button}
          loading={loadingBTN}
          name="editElement"
          content="Save"
          className="save-btn"
          primary
          onClick={this.handleEdit}
        />
      </Form>
    );
  }
}

export default EditYoutubeDl;

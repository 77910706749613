import React, { Component } from "react";
import { Form, Button, Dropdown } from "semantic-ui-react";
import "./user.css";

class EditUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      error: {},
      loadingBTN: false,
    };
  }

  // Lifecycle Methods

  componentDidMount() {
    const { eData } = this.props;
    this.setState({
      userData: {
        ...eData,
      },
    });
  }

  // End of Lifecycle Methods

  handleChange = (e) => {
    const { name, value } = e.target;
    const { userData } = this.state;
    userData[name] = value;
    this.setState({ userData });
  };

  handleRoleChange = (e, data) => {
    const { name, value } = data;
    const { error } = this.state;
    if (value) delete error.role;
    this.setState((state) => ({
      userData: {
        ...state.userData,
        [name]: value,
        error,
      },
    }));
  };

  handleEdit = () => {
    const { editUserDetail, closeModal, showStatus } = this.props;
    let { userData } = this.state;
    let message = "";
    let response = {
      success: false,
      error: false,
    };
    if (this.validateFields()) {
      this.setState({
        loadingBTN: true,
      });
      editUserDetail(userData)
        .then((res) => {
          message = "User Edited";
          response.success = true;
          closeModal();
          showStatus(message, response);
          this.setState({
            userData: {},
            loadingBTN: false,
          });
        })
        .catch((err) => {
          message = (err.data && err.data.message) || "User not Edited !";
          response.error = true;
          closeModal();
          showStatus(message, response);
          this.setState({
            loadingBTN: false,
          });
        });
    }
  };

  validateFields = () => {
    const {
      userData: { role },
      error,
    } = this.state;
    if (!role) {
      error.role = { isError: true, message: "Select any role" };
    } else {
      delete error.role;
    }

    this.setState({
      error,
    });
    return !Object.keys(error).length > 0;
  };

  render() {
    const { userData, error, loadingBTN } = this.state;
    return (
      <div>
        <div>
          <Form>
            <Form.Group widths="equal">
              <div className="error-text">
                <Form.Field>
                  <span>
                    <b>Roles</b>
                  </span>
                  <Dropdown
                    options={[
                      { text: "Admin", value: "admin" },
                      { text: "User", value: "user" },
                    ]}
                    placeholder="Select Role"
                    name="role"
                    fluid
                    onChange={(e, data) => this.handleRoleChange(e, data)}
                    value={userData.role || ""}
                    selection
                    error={error.role && error.role.isError}
                  />
                </Form.Field>
                <span
                  className={
                    error.role && error.role.isError ? "show" : "error-card"
                  }
                >
                  {error.role && error.role.isError && error.role.message}
                </span>
              </div>
            </Form.Group>
            <Form.Field
              control={Button}
              loading={loadingBTN}
              name="editUser"
              content="Save"
              className="save-btn"
              primary
              onClick={this.handleEdit}
            />
          </Form>
        </div>
      </div>
    );
  }
}

export default EditUsers;

import * as youtube_dl from "../services/youtubeDlService";

import {
  ELEMENT_LOADING,
  GET_ELEMENTS,
  DOWNLOAD_ELEMENT,
  GET_PUBLIC_ELEMENTS,
  EDIT_ELEMENT,
} from "../reducers/youtubeDlReducer";
import { downloadYoutubeFile } from "../utility";

export const getAllElements =
  (userID, page, size, searchText, sortDirection, sortedField) =>
  (dispatch) => {
    dispatch({ type: ELEMENT_LOADING, payload: true });
    youtube_dl
      .getElements(userID, page, size, searchText, sortDirection, sortedField)
      .then((res) => {
        dispatch({ type: GET_ELEMENTS, payload: res.data });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => dispatch({ type: ELEMENT_LOADING, payload: false }));
  };

export const addElement = (payload) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: ELEMENT_LOADING, payload: true });
    youtube_dl
      .addElement(payload)
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch({ type: ELEMENT_LOADING, payload: false }));
  });

export const deleteElement = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    youtube_dl
      .deleteElement(id)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });

export const downloadElement = (id, url) => (dispatch) => {
  dispatch({ type: DOWNLOAD_ELEMENT, payload: { id, loading: true } });
  downloadYoutubeFile(url).finally(() =>
    dispatch({ type: DOWNLOAD_ELEMENT, payload: { id, loading: false } })
  );
};

export const getPublicElements = () => (dispatch) => {
  dispatch({ type: ELEMENT_LOADING, payload: true });
  youtube_dl
    .getPublicElements()
    .then((result) => {
      dispatch({ type: GET_PUBLIC_ELEMENTS, payload: result.data });
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({ type: ELEMENT_LOADING, payload: false }));
};

export const editElement = (payload) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: ELEMENT_LOADING, payload: true });
    youtube_dl
      .editElement(payload)
      .then((res) => {
        dispatch({
          type: EDIT_ELEMENT,
          payload: {
            ...payload,
            isEdited: res.data.isEdited,
          },
        });
        resolve();
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch({ type: ELEMENT_LOADING, payload: false }));
  });

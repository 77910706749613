import moment from 'moment';
import * as fileService from '../services/fileService';
import {
  FILE_LOADING, ADD_FILE, MOVE_FILE, GET_FILE, GET_PUBLIC_FILE, EDIT_FILE_NAME, EDIT_VISIBILITY, DELETE_FILE,
  GET_ONSITE_FILE, GET_SHARED_FILE,
} from '../reducers/fileReducer';

export const getFile = () => (dispatch) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.getFile()
    .then(result => {
      dispatch({type: GET_FILE, payload: result.data});
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
}

export const getPublicFiles = (folderID) => (dispatch) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.getPublicFiles(folderID)
    .then(result => {
      dispatch({type: GET_PUBLIC_FILE, payload: result.data});
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
}

export const getOnSiteFiles = (folderID) => (dispatch) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.getOnSiteFiles(folderID)
    .then(result => {
      dispatch({type: GET_ONSITE_FILE, payload: result.data});
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
}

export const getSharedFiles = (folderID) => (dispatch) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.getSharedFiles(folderID)
    .then(result => {
      dispatch({type: GET_SHARED_FILE, payload: result.data});
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
}

export const insertFile = (payload) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.addFile(payload)
    .then(result => {
      let {insertId: id} = result.data;
      dispatch({
        type: ADD_FILE,
        payload: {
          id,
          created: moment().format(),
          ...payload,
        }
      });
      resolve();
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
});

export const moveFile = (id, folder_id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.moveFile(id, folder_id)
    .then(result => {
      dispatch({type: MOVE_FILE, payload: {id, folder_id}});
      resolve();
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
});

export const editFileName = (id, name) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.editFileName(id, name)
    .then(result => {
      dispatch({type: EDIT_FILE_NAME, payload: {id, name}});
      resolve();
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
});

export const editVisibility = (id, is_public) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.editVisibility(id, is_public)
    .then(result => {
      dispatch({type: EDIT_VISIBILITY, payload: {id, is_public}});
      resolve();
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
});

export const deleteFile = (id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FILE_LOADING, payload: true});
  fileService.deleteFile(id)
    .then(result => {
      dispatch({type: DELETE_FILE, payload: {id}});
      resolve();
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FILE_LOADING, payload: false}));
});

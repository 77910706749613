import React from "react";
import {Form, Dropdown} from "semantic-ui-react";

import CustomModal from "../CustomModal";
import Snackbar from "../Snackbar";

import {shareFolder, folderSharedWith} from "../../services/folderService";

class ShareFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listLoading: false,
      initUserIDs: [],
      userIDs: [],
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    }
  }

  componentDidMount() {
    const {folder} = this.props;
    if(folder && folder.id) {
      this.getSharedUserList(folder.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {folder} = nextProps;
    if(folder && folder.id) {
      this.getSharedUserList(folder.id);
    }
  }

  handleState = (e, {name, value}) => {
    this.setState({
        [name]: value
    });
  };

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  getSharedUserList = (folderID) => {
    this.setState({listLoading: true});
    folderSharedWith(folderID)
    .then(result => {
      let IDs = result.data.map(x => x.user_id);
      this.setState({listLoading: false, initUserIDs: [...IDs], userIDs: [...IDs]});
    })
    .catch(error => {
      this.setState({
        listLoading: false, 
        snackbar: {
          open: true,
          message: error.data && error.data.message,
          success: false,
          error: true
        }
      })
    })
  }

  onSubmit = () => {
    const {onClose, folder: {id: folderID, name: folderName}} = this.props;
    const {userIDs, initUserIDs} = this.state;
    const deletedUser = initUserIDs.filter(t => !userIDs.includes(t));
    const addedUser = [...new Set(userIDs.filter(t => !initUserIDs.includes(t)))];
    if (folderID) {
      this.setState({loading: true});
      shareFolder({folderID, addedUser, deletedUser})
        .then(result => {
          this.setState({
            loading: false,
            initUserIDs: [],
            userIDs: [],
            snackbar: {
              open: true,
              message: `Successfully shared folder ${folderName}!`,
              success: true,
              error: false
            }
          });
          onClose();
        })
        .catch(error => {
          this.setState({
            loading: false,
            snackbar: {
              open: true,
              message: (error.data && error.data.message) || "Internal server error!",
              success: false,
              error: true
            }
          });
        })
    }
  }

  render() {
    const {userIDs, snackbar, loading, listLoading} = this.state;
    const {open, onClose, folder, users} = this.props;
    return <>
      <CustomModal
        open={open}
        onClose={() => { this.setState({userIDs:[]}); onClose();}}
        footer={true}
        title={`Share Folder ${folder && folder.name}`}
        onSubmit={this.onSubmit}
        submitLoader={loading}
      >
        <Form>
            <span>Users:</span>
            {
              !listLoading && <Dropdown
                  placeholder='Users'
                  name='userIDs'
                  fluid
                  multiple
                  search
                  selection
                  closeOnChange
                  options={users.map(x => ({text: x.username, value: x.employee_key}))}
                  wrapSelection={true}
                  value={userIDs}
                  onChange={this.handleState}
              />
            }
        </Form>
      </CustomModal>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
        success={snackbar.success}
        onClose={this.resetSnackbar}
      />
    </>
  }
}

export default ShareFolder;

import React from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button, Divider,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Popup,
} from "semantic-ui-react";
import moment from "moment";
import { getOnSiteFiles } from "../../actions/fileActions";
import { getOnSiteFolder } from "../../actions/folderActions";
import { downloadFile, getIconBasedOnFile } from "../../utility";
import DownloadAs from "../DownloadAs";
import Snackbar from "../Snackbar";

import "../../containers/home.css";

class OnSiteMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initFolder: null,
      openDownloadAs: false,
      fileName: null,
      downloadURL: null,
      folderID: null,
      folderPath: [{
        folder_id: null,
        name: 'Home'
      }],
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      },
    };
  }

  componentDidMount() {
    const folder = new URLSearchParams(this.props.location.search).get("folder");
    if(folder) this.setState({initFolder: parseInt(folder)});
    const { fetchOnSiteFiles, fetchOnSiteFolder } = this.props;
    fetchOnSiteFiles(null);
    fetchOnSiteFolder(null);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    let folderID = this.state.initFolder;
    let onSiteData = nextProps.folders.onSiteData;
    if(folderID && onSiteData.length) {
      let folder = onSiteData.filter(x => x.id === folderID);
      if(folder.length) {
        this.handleFolderClick(this.state.initFolder, folder[0].name);
        this.setState({initFolder: null});
      } else {
        this.setState({
          initFolder: null,
          snackbar: {
            open: true,
            message: 'Folder you are looking for is not found!',
            success: false,
            error: true
          }
        })
      }
    }
  }

  changeFolder = (folderID, index) => {
    const { fetchOnSiteFiles, fetchOnSiteFolder } = this.props;
    this.setState(state => ({
      ...state,
      folderID,
      folderPath: state.folderPath.filter((_, i) => i <= index)
    }), () => {
      fetchOnSiteFiles(folderID);
      fetchOnSiteFolder(folderID)
    });
  }

  handleFolderClick = (folder_id, name) => {
    const { fetchOnSiteFiles, fetchOnSiteFolder } = this.props;
    this.setState(state => ({
      ...state,
      folderID: folder_id,
      folderPath: [...state.folderPath, {folder_id, name}]
    }), () => {
      fetchOnSiteFiles(folder_id);
      fetchOnSiteFolder(folder_id)
    });
  }

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  render() {
    const { openDownloadAs, fileName, downloadURL, folderPath, folderID, snackbar } = this.state;
    return (
      <>
        <Grid className="home-container">
          <GridColumn
            className="main-col"
            mobile={16}
            tablet={12}
            computer={16}
          >
            <div className="main-details" style={{ height: "100% !important" }}>
              <Breadcrumb size="huge" className="custom-breadcrumb">
                {
                  folderPath.map((f, i) => <>
                    <Breadcrumb.Section
                      active={i === folderPath.length - 1}
                      link
                      onClick={() => this.changeFolder(f.folder_id, i)}
                    >
                      <span className="unselectable">{f.name}</span>
                    </Breadcrumb.Section>
                    {
                      i !== folderPath.length - 1 && <Breadcrumb.Divider icon='right chevron'/>
                    }
                  </>)
                }
              </Breadcrumb>
              <Divider/>
              <h2 className="header">Folders</h2>
              <Grid className="w-100 m-0" columns={3}>
                <GridRow>
                  {this.props.folders.loading ? (
                    <div className="d-flex w-100 justify-content-center text-light">
                      Loading...
                    </div>
                  ) : this.props.folders.onSiteData.length > 0 ? (
                    this.props.folders.onSiteData.map((f) => (
                      <GridColumn key={f.id} mobile={16} tablet={5} computer={3}>
                        <div
                          className="folder-btn w-100 ui basic button"
                          onClick={
                            (e) => {
                              if (e.ctrlKey) this.handleFolderClick(f.id, f.name)
                            }
                          }
                          onDoubleClick={() => {
                            this.handleFolderClick(f.id, f.name)
                          }}
                        >
                          <div className="d-flex text-truncate">
                            <Icon name='folder'/>
                            <Popup
                              trigger={<span className="text-truncate text-left">{f.name}</span>}
                              inverted
                              hideOnScroll
                            >
                              <div>Name: {f.name}</div>
                              <div>Owner: {f.owner}</div>
                              <div>Created At: {moment(f.created).format('YYYY-MM-DD HH:mm:ss')}</div>
                              { f.modified && <div>Last Modified At: {moment(f.modified).format('YYYY-MM-DD HH:mm:ss')}</div> }
                            </Popup>
                            <div className="folder-action">
                              <Dropdown
                                key={`dropdown-${f.id}`}
                                simple
                                closeOnBlur
                                closeOnEscape
                                closeOnChange
                                direction='left'
                                icon={<Icon size="small" name="ellipsis vertical"/>}
                              >
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => this.handleFolderClick(f.id, f.name)}>
                                    <Icon name="arrow up" color="blue"/>
                                    Open
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </GridColumn>
                    ))
                  ) : (
                    <div className="d-flex w-100 justify-content-center text-light">
                      No Folders
                    </div>
                  )}
                </GridRow>
              </Grid>
              {
                folderID && <>
                  <h2 className="header">Files</h2>
                  <Grid className="w-100 m-0" columns={3}>
                    <GridRow>
                      {this.props.files.loading ? (
                        <div className="d-flex w-100 justify-content-center text-light">
                          Loading...
                        </div>
                      ) : this.props.files.onSiteData.length > 0 ? (
                        this.props.files.onSiteData.map((f) => (
                          <GridColumn
                            mobile={16}
                            tablet={5}
                            computer={3}
                            key={f.id}
                          >
                            <Button
                              className="file-btn w-100"
                              basic
                              onDoubleClick={() =>
                                window.open(f.access_url, "_blank")
                              }
                            >
                              <div>
                                <div className="file-img">
                                {
                                  f.thumbnail !== null
                                  ? <img src={`https://d2a39xd6ecmbf2.cloudfront.net/drive/thumbnail/${f.thumbnail}`} height='150' width='250'/>
                                  : <Icon name={getIconBasedOnFile(f.name)}/>
                                }
                                </div>
                                <div className="d-flex text-truncate file-name">
                                  <Popup
                                    trigger={
                                      <span className="text-truncate text-left">
                                    {f.name}
                                  </span>
                                    }
                                    inverted
                                    hideOnScroll
                                  >
                                    <div>Name: {f.name}</div>
                                    <div>Owner: {f.owner}</div>
                                    <div>
                                      Created At:
                                      {moment(f.created).format(
                                        " YYYY-MM-DD HH:mm:ss"
                                      )}
                                    </div>
                                    {f.modified && (
                                      <div>
                                        Last Modified At:
                                        {moment(f.modified).format(
                                          " YYYY-MM-DD HH:mm:ss"
                                        )}
                                      </div>
                                    )}
                                  </Popup>
                                  <div className="folder-action">
                                    <Dropdown
                                      key={`dropdown-${f.id}`}
                                      simple
                                      closeOnBlur
                                      closeOnEscape
                                      closeOnChange
                                      direction="left"
                                      icon={
                                        <Icon
                                          size="small"
                                          name="ellipsis vertical"
                                        />
                                      }
                                    >
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            window.open(f.access_url, "_blank")
                                          }
                                        >
                                          <Icon name="arrow up" color="blue" />
                                          Open
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                          navigator.clipboard.writeText(f.access_url)
                                          this.setState({
                                            snackbar: {
                                              open: true,
                                              message: 'Copied to clipboard!',
                                              success: true,
                                              error: false
                                            }
                                          });
                                        }}>
                                          <Icon name="copy outline" color="blue"/>
                                          Copy Link
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            downloadFile(f.access_url, f.name)
                                          }
                                        >
                                          <Icon name="download" color="blue" />
                                          Download
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.setState({
                                              openDownloadAs: true,
                                              fileName: f.name,
                                              downloadURL: f.access_url,
                                            })
                                          }
                                        >
                                          <Icon name="download" />
                                          Download As
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </GridColumn>
                        ))
                      ) : (
                        <div className="d-flex w-100 justify-content-center text-light">
                          No Files
                        </div>
                      )}
                    </GridRow>
                  </Grid>
                </>
              }
            </div>
          </GridColumn>
        </Grid>
        <DownloadAs
          open={openDownloadAs}
          onClose={() =>
            this.setState({
              openDownloadAs: false,
              fileName: null,
              downloadURL: null,
            })
          }
          fileName={fileName}
          fileURL={downloadURL}
        />
        <Snackbar
          open={snackbar.open}
          message={snackbar.message}
          error={snackbar.error}
          success={snackbar.success}
          onClose={this.resetSnackbar}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  files: state.fileReducer,
  folders: state.folderReducer,
});

const mapDispatchToProps = {
  fetchOnSiteFiles: getOnSiteFiles,
  fetchOnSiteFolder: getOnSiteFolder,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnSiteMedia);

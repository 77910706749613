import React from 'react';
import './modal.css';

import {
  Modal, Button, Transition, Confirm, Header, Icon
} from 'semantic-ui-react';

const CustomModal = (props) => {
  const {
    title,
    subTitle,
    open,
    footer,
    dimmer,
    children,
    size = 'tiny',
    onClose,
    onSubmit,
    txtClose,
    txtSuccess,
    submitLoader = false
  } = props;
  return (
    <div className="modal-container">
      <Transition.Group animation="fade up" duration={400}>
        <Modal dimmer={dimmer || 'blurring'} open={open} size={size} onClose={onClose} className="blur-modal">
          <Modal.Header className="header-wrapper" style={{backgroundColor: '#f0f8ff'}}>
            <div className="header-title">
              <p className="title">{title || 'Title'}</p>
              <p className="sub-title">{subTitle || 'Press ESC to close'}</p>
            </div>
            <div className="header-close">
              <Button icon="close" size="small" onClick={onClose}/>
            </div>
          </Modal.Header>
          <Modal.Content image>
            <Modal.Description className="modal-description">
              {children}
            </Modal.Description>
          </Modal.Content>
          {
            footer
            && (
              <Modal.Actions>
                <Button color="black" onClick={onClose}>
                  {txtClose || 'Close'}
                </Button>
                <Button
                  positive
                  icon="checkmark"
                  labelPosition="right"
                  content={txtSuccess || 'Submit'}
                  onClick={onSubmit}
                  loading={submitLoader}
                />
              </Modal.Actions>
            )
          }
        </Modal>
      </Transition.Group>
    </div>
  );
}

export const ConfirmModal = (props) => {
  const {
    open, header, onCancelClick, onYesClick, primaryKey, loading, yesButtonText = 'Yes', content, icon
  } = props;
  return (
    <Transition.Group animation="fade up" duration={400}>
      {
        open
        && (
          <Confirm
            content={content || `Are you sure you want to Delete ${primaryKey} ?`}
            header={<Header icon={icon || "archive"} content={header}/>}
            open={open}
            dimmer="blurring"
            cancelButton={(
              <Button color="red">
                <Icon name="remove"/> Cancel
              </Button>
            )}
            confirmButton={(
              <Button positive loading={loading}>
                <Icon name="checkmark"/> {yesButtonText}
              </Button>
            )}
            onCancel={onCancelClick}
            onConfirm={onYesClick}
            size="small"
          />
        )
      }
    </Transition.Group>
  );
};

export default CustomModal;

import React from 'react';
import {connect} from 'react-redux';
import {Loader, Segment, Dimmer} from 'semantic-ui-react';
import {checkAuthToken} from '../services/authService';
import NavBar from "./Navbar";

class CustomRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  async componentDidMount() {
    await checkAuthToken();
  }

  render() {
    const {component: Component, allowedUsers, auth: {role, isLoggedIn}} = this.props;

    if (!isLoggedIn || !role) {
      return (
        <Dimmer inverted active>
          <Loader size="large">Loading</Loader>
        </Dimmer>
      );
    }

    if (!allowedUsers.includes(role)) {
      return (
        <UnauthorizedMessage/>
      );
    }

    return <NavBar>
      <Component {...this.props} />
    </NavBar>
  }
}

const UnauthorizedMessage = () => (
  <Segment>
    <div align="center">
      <h3> You are not authorized for this Route, Contact Administrator!</h3>
    </div>
  </Segment>
);

const mapStateToProps = state => ({
  auth: state.authReducer
});

export default connect(mapStateToProps, null)(CustomRoute);

import * as users from "../services/userServices";

import {
  GET_USER,
  EDIT_USER,
  USER_LOADING,
  LIST_USER,
} from "../reducers/userReducer";

export const getAllUsers =
  (page, size, field, sortDirection, searchText) => (dispatch) => {
    dispatch({ type: USER_LOADING, payload: true });
    users
      .getUsers(page, size, field, sortDirection, searchText)
      .then((res) => {
        dispatch({ type: GET_USER, payload: res.data });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => dispatch({ type: USER_LOADING, payload: false }));
  };

export const editUser = (payload) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: USER_LOADING, payload: true });
    users
      .editUser(payload)
      .then((res) => {
        dispatch({
          type: EDIT_USER,
          payload: {
            ...payload,
            isEdited: res.data.isEdited,
          },
        });
        resolve();
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => dispatch({ type: USER_LOADING, payload: false }));
  });

export const listUser = () => (dispatch) => {
  dispatch({ type: USER_LOADING, payload: true });
  users
    .userList()
    .then((result) => {
      dispatch({ type: LIST_USER, payload: result.data });
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => dispatch({ type: USER_LOADING, payload: false }));
};

import * as authService from '../services/authService';
import cookies from '../cookies';

import {
  LOGIN_SUCCESS, LOGIN_FAILED
} from '../reducers/authReducer';

export const login = data => (dispatch) => {
  authService.login(data)
    .then((response) => {
      const { firstName, lastName, authToken, role, userID } = response.data;
      if(authToken && role && userID) {
        cookies.set('isLoggedIn', true);
        cookies.set('authToken', authToken);
        cookies.set('role', role);
        cookies.set('firstName', firstName.charAt(0).toUpperCase() + firstName.slice(1));
        cookies.set('lastName', lastName.charAt(0).toUpperCase() + lastName.slice(1));
        cookies.set('userID', userID);
        dispatch({ type: LOGIN_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: LOGIN_FAILED, payload: { isLoggedIn: false, message: 'Login Failed!' } });
      }
    })
    .catch((err) => {
      dispatch({ type: LOGIN_FAILED, payload: { isLoggedIn: false, message: err.data && err.data.message } });
    });
};

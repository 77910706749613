import baseService from "./baseService";

export const getUsers = (page, size, field, sort, search) =>
  baseService.get(`/user`, {
    headers: {
      page,
      size,
      field,
      sort,
      search,
    },
  });

export const editUser = (payload) => baseService.put("/user", payload);

export const userList = () => baseService.get("/user/list");

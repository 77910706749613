import baseService from './baseService';

export function addFolder(body) {
  return baseService.post('/folder', body);
}

export function editFolderName(id, name) {
  return baseService.put('/folder/rename', { id, name });
}

export function editFolderPermission(id, permission) {
  return baseService.put('/folder/permission', { id, permission });
}

export function getFolder() {
  return baseService.get('/folder');
}

export function getPublicFolder(folderID) {
  return baseService.get(`/folder/public/${folderID}`);
}

export function getOnSiteFolder(folderID) {
  return baseService.get(`/folder/site/${folderID}`);
}

export function moveFolder(id, parent) {
  return baseService.put('/folder/move', { id, parent });
}

export function removeFolder(folders) {
  return baseService.put('/folder/delete', { folders });
}

export function getSharedFolder(folderID) {
  return baseService.get(`/folder/shared/${folderID}`);
}

export function shareFolder(data) {
  return baseService.post('/folder/share', data);
}

export function folderSharedWith(folderID) {
  return baseService.get(`/folder/sharedWith/${folderID}`);
}

import React from "react";
import {Accordion, Icon} from "semantic-ui-react";

import CustomModal from '../CustomModal';
import Snackbar from "../Snackbar";
import './index.css';

class MoveFolderOrFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeIndex: [0],
      selectedFolder: null,
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    }
  }

  componentDidMount() {
    this.fillSideBar();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.fillSideBar();
  }

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  fillSideBar = () => {
    const {folders: folderData} = this.props;
    let root = folderData.filter(x => !x.parent);
    this.attachChild(root, folderData);
    this.setState({data: root, activeIndex: [0], selectedFolder: null});
  }

  attachChild = (root, folderData) => {
    root.filter(x => x.hasOwnProperty('parent')).map(x => {
      x.children = folderData.filter(y => y.parent === x.id);
      if (x.children.length) {
        this.attachChild(x.children, folderData);
      }
    });
  }

  getHomePanel = () => {
    const {data, activeIndex, selectedFolder} = this.state;
    let path = [{folder_id: null, name: 'Home'}];
    let panelData = this.getPanels(data, path);
    return <>
      <Accordion.Title
        active={activeIndex.includes(0)}
        index={0}
        className={!selectedFolder ? 'selected' : ''}
        onClick={(e) => {
          if (e.ctrlKey)
            this.changeFolder(path, null)
          else
            this.handleClick(0)
        }}
        onDoubleClick={() => {
          this.changeFolder(path, null)
        }}
      >
        <Icon name='dropdown'/>
        <Icon name='home'/>
        <span className="unselectable">Home</span>
      </Accordion.Title>
      <Accordion.Content active={activeIndex.includes(0)}>
        {
          <Accordion.Accordion panels={panelData}/>
        }
      </Accordion.Content>
    </>
  }

  handleClick = (index) => {
    const {activeIndex} = this.state
    let newIndex;
    if (activeIndex.includes(index)) {
      newIndex = activeIndex.filter(i => i !== index);
    } else {
      newIndex = [...activeIndex, index];
    }

    this.setState({activeIndex: newIndex})
  }

  getPanels = (data, path) => {
    const {activeIndex, selectedFolder} = this.state;

    return data.filter(x => x.hasOwnProperty('children')).map(x =>
      <>
        <Accordion.Title
          active={activeIndex.includes(x.id)}
          index={x.id}
          className={x.id === selectedFolder ? 'selected' : ''}
          onClick={(e) => {
            if (e.ctrlKey)
              this.changeFolder([...path, {folder_id: x.id, name: x.name}], x.id, x.name)
            else
              this.handleClick(x.id)
          }}
          onDoubleClick={() => {
            this.changeFolder([...path, {folder_id: x.id, name: x.name}], x.id)
          }}
        >
          <Icon name='dropdown'/>
          <Icon name={activeIndex.includes(x.id) ? 'folder open' : 'folder'}/>
          <span className="unselectable">{x.name}</span>
        </Accordion.Title>
        <Accordion.Content active={activeIndex.includes(x.id)}>
          {
            x.children.filter(child => child.hasOwnProperty('children')).length > 0 ?
              <Accordion.Accordion
                panels={this.getPanels(x.children, [...path, {folder_id: x.id, name: x.name}])}/>
              : ''
          }
        </Accordion.Content>
      </>
    );
  }

  onSubmit = () => {
    const {moveFolderAction, moveFileAction, fileForAction, folderForAction, onClose} = this.props;
    const {selectedFolder} = this.state;
    if (fileForAction) {
      moveFileAction(fileForAction, selectedFolder)
        .then(result => {
          this.setState({
            activeIndex: [0],
            selectedFolder: null,
            snackbar: {
              open: true,
              message: 'File Moved Successfully!',
              success: true,
              error: false
            }
          });
        })
        .catch(error => {
          this.setState({
            activeIndex: [0],
            selectedFolder: null,
            snackbar: {
              open: true,
              message: error.data && error.data.message,
              success: false,
              error: true
            }
          });
        }).finally(onClose);
    } else if (folderForAction) {
      moveFolderAction(folderForAction, selectedFolder)
        .then(result => {
          this.setState({
            activeIndex: [0],
            selectedFolder: null,
            snackbar: {
              open: true,
              message: 'Folder Moved Successfully!',
              success: true,
              error: false
            }
          });
        })
        .catch(error => {
          this.setState({
            activeIndex: [0],
            selectedFolder: null,
            snackbar: {
              open: true,
              message: error.data && error.data.message,
              success: false,
              error: true
            }
          });
        }).finally(onClose);
    }
  }

  changeFolder = (path, folder_id) => {
    this.setState({path, selectedFolder: folder_id});
  }

  render() {
    const {open, onClose, folderLoading, fileLoading, fileForAction, folderForAction} = this.props;
    const { snackbar } = this.state;
    return <>
      <CustomModal
        open={open}
        onClose={onClose}
        footer={true}
        title={`Move ${fileForAction ? 'File' : folderForAction ? 'Folder' : ''}`}
        subTitle='Double click or Ctrl + click to select the folder'
        onSubmit={this.onSubmit}
        className="custom-modal"
        submitLoader={folderLoading || fileLoading}
      >
        <Accordion styled className="custom-accordion move-file-wrap">
          {
            this.getHomePanel()
          }
        </Accordion>
      </CustomModal>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
        success={snackbar.success}
        onClose={this.resetSnackbar}
      />
    </>
  }
}

export default MoveFolderOrFile;

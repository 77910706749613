import React, { Component } from "react";
import { Form, Button, Dropdown, Input, Checkbox } from "semantic-ui-react";

class AddYoutubeDl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      youtubeDlData: {
        source: "",
        name: "",
        position: "sqaure",
        start: "",
        end: "",
        is_public: false,
      },
      error: {},
      loadingBTN: false,
    };
  }

  handleInputChange = (e, data) => {
    const { name, value } = data;
    const { error } = this.state;
    if (value) delete error.position;
    this.setState((state) => ({
      youtubeDlData: {
        ...state.youtubeDlData,
        [name]: value,
        error,
      },
    }));
  };

  handleAdd = () => {
    const { addYoutubeDlData, closeModal, showStatus, fetchElements } =
      this.props;
    let { youtubeDlData } = this.state;
    let message = "";
    let response = {
      success: false,
      error: false,
    };
    if (this.validateFields()) {
      this.setState({
        loadingBTN: true,
      });

      addYoutubeDlData(youtubeDlData)
        .then((res) => {
          message = "Element Added";
          response.success = true;
          closeModal();
          showStatus(message, response);
          this.setState({
            youtubeDlData: {
              source: "",
              name: "",
              position: "center",
              start: "",
              end: "",
              is_public: false,
            },
            loadingBTN: false,
          });
          fetchElements();
        })
        .catch((err) => {
          if (
            err.data &&
            err.data.error &&
            err.data.error.code === "ER_DUP_ENTRY"
          ) {
            this.setState({
              error: {
                name: { isError: true, message: "Name Already Exists!" },
              },
              loadingBTN: false,
            });
          } else {
            message = (err.data && err.data.message) || "Element not Added !";
            response.error = true;
            closeModal();
            showStatus(message, response);
            this.setState({
              loadingBTN: false,
            });
          }
        });
    }
  };

  validateFields = () => {
    let {
      youtubeDlData: { source, name, start, end },
      error,
    } = this.state;
    if (!source) {
      error.source = { isError: true, message: "Enter URI" };
    } else {
      delete error.source;
    }
    if (!name) {
      error.name = { isError: true, message: "Enter Name" };
    } else {
      delete error.name;
    }
    if (!start) {
      error.start = { isError: true, message: "Enter start time" };
    } else {
      delete error.start;
    }
    if (!end) {
      error.end = { isError: true, message: "Enter end time" };
    } else {
      delete error.end;
    }

    if (start && end) {
      let temp1 = start.split(":");
      if (temp1.length === 2 && !isNaN(temp1[0]) && !isNaN(temp1[1])) {
        start = Number(temp1[0].trim()) * 60 + Number(temp1[1].trim());
      }

      let temp2 = end.split(":");
      if (temp2.length === 2 && !isNaN(temp2[0]) && !isNaN(temp2[1])) {
        end = Number(temp2[0].trim()) * 60 + Number(temp2[1].trim());
      }

      if (start >= end) {
        error.start = {
          isError: true,
          message: "Start time should not be greater than end time.",
        };
      }
    }

    if (source && !source.match(/https:\/\/www\.youtube\.com\/watch\?v=\.*/)) {
      error.source = { isError: true, message: "Invalid URL!" };
    }

    this.setState({
      error,
    });
    return !Object.keys(error).length > 0;
  };

  render() {
    const { youtubeDlData, error, loadingBTN } = this.state;
    return (
      <Form>
        <Form.Field
          name="name"
          value={youtubeDlData.name}
          label="Name"
          type="text"
          control={Input}
          placeholder="Name"
          error={
            error.name &&
            error.name.isError &&
            error.name.message === "Name Already Exists!"
              ? {
                  content: error.name.message,
                  pointing: "below",
                }
              : error.name && error.name.isError
          }
          onChange={(e, data) => this.handleInputChange(e, data)}
        ></Form.Field>
        <Form.Field
          name="source"
          value={youtubeDlData.source}
          label="URL"
          type="text"
          control={Input}
          placeholder="https://www.youtube.com/watch?v=xyz"
          error={
            error.source &&
            error.source.isError &&
            error.source.message === "Invalid URL!"
              ? {
                  content: error.source.message,
                  pointing: "below",
                }
              : error.source && error.source.isError
          }
          onChange={(e, data) => this.handleInputChange(e, data)}
        ></Form.Field>

        <Form.Group widths="equal">
          <Form.Field
            label="Position"
            type="text"
            control={Dropdown}
            options={[
              { text: "Left", value: "left" },
              { text: "Right", value: "right" },
              { text: "Center", value: "center" },
              { text: "Sqaure", value: "sqaure" },
            ]}
            name="position"
            fluid
            onChange={(e, data) => this.handleInputChange(e, data)}
            value={youtubeDlData.position || "sqaure"}
            selection
          ></Form.Field>
          <Form.Field
            name="start"
            control={Input}
            type="text"
            label="Start"
            placeholder="MM:SS"
            onChange={(e, data) => this.handleInputChange(e, data)}
            error={error.start && error.start.isError}
          ></Form.Field>
          <Form.Field
            name="end"
            control={Input}
            type="text"
            label="End"
            placeholder="MM:SS"
            onChange={(e, data) => this.handleInputChange(e, data)}
            error={error.end && error.end.isError}
          ></Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Is Public</label>
          <Checkbox
            id="chk-is-public"
            toggle
            checked={youtubeDlData.is_public}
            onChange={(e, { checked }) =>
              this.setState((state) => ({
                youtubeDlData: { ...state.youtubeDlData, is_public: checked },
              }))
            }
          />
        </Form.Field>
        <Form.Field
          control={Button}
          loading={loadingBTN}
          name="addElement"
          content="Save"
          className="save-btn"
          primary
          onClick={this.handleAdd}
        />
      </Form>
    );
  }
}

export default AddYoutubeDl;

import React, { Component } from "react";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Form,
  Input,
  Select,
  Label,
  Loader,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { getAllUsers, editUser } from "../../actions/userActions";
import MyModal from "../CustomModal";
import "./user.css";
import EditUserPage from "./editUser";
import MessageBar from "../Snackbar";

const PaginationComponent = ({ totalPages, activePage, handlePageChange }) => (
  <Pagination
    totalPages={totalPages}
    activePage={activePage}
    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
    prevItem={{ content: <Icon name="angle left" />, icon: true }}
    nextItem={{ content: <Icon name="angle right" />, icon: true }}
    onPageChange={handlePageChange}
  />
);

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      page: 1,
      size: 10,
      searchText: "",
      isEditing: false,
      editData: {},
      showMsg: false,
      message: "",
      response: {},
      sortedField: "created",
      sortDirection: "desc",
    };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = () => {
    const { fetchUsersAction } = this.props;
    const { page, size, searchText, sortDirection, sortedField } = this.state;
    fetchUsersAction(page, size, sortedField, sortDirection, searchText);
  };

  showStatus = (msg, res) => {
    this.setState({
      showMsg: true,
      message: msg,
      response: res,
    });
  };

  handleChange = (e, { value }) => {
    const { fetchUsersAction } = this.props;
    const { page, size, searchText, sortedField, sortDirection } = this.state;
    const diff = Math.ceil(value / size);
    if (size !== value) {
      fetchUsersAction(page, size, sortedField, sortDirection, searchText);
      this.setState({
        size: value,
        page: Math.ceil(page / diff),
      });
    }
  };

  handleEdit = (user) => {
    this.setState({
      editData: { ...user },
      isModalOpen: true,
    });
  };

  handlePageChange = (e, { activePage }) => {
    const { fetchUsersAction } = this.props;
    const { size, searchText, sortedField, sortDirection } = this.state;
    fetchUsersAction(activePage, size, sortedField, sortDirection, searchText);
    this.setState({
      page: activePage,
    });
  };

  handleSearchTextChange = (e) => {
    const { value } = e.target;
    this.setState({
      searchText: value,
    });
  };

  handleSearchClick = () => {
    const { size, page, searchText, sortedField, sortDirection } = this.state;
    const { fetchUsersAction } = this.props;
    fetchUsersAction(page, size, sortedField, sortDirection, searchText);
  };

  showMessage = () => {
    const {
      message = "",
      response: { success = false, error = false },
      showMsg,
    } = this.state;
    if (success) {
      return (
        <MessageBar
          open={showMsg}
          message={message}
          success
          onClose={() =>
            setTimeout(() => {
              this.setState({ showMsg: false, response: {} });
            }, 1500)
          }
        />
      );
    } else if (error) {
      return (
        <MessageBar
          open={showMsg}
          message={message}
          error
          onClose={() =>
            setTimeout(() => {
              this.setState({ showMsg: false, response: {} });
            }, 1500)
          }
        />
      );
    }
    return null;
  };

  handleSort = (columnName) => {
    let { sortedField, sortDirection } = this.state;
    if (sortedField === columnName) {
      if (sortDirection === "asc") {
        sortDirection = "desc";
      } else {
        sortDirection = "asc";
      }
    } else {
      sortedField = columnName;
      sortDirection = "asc";
    }
    this.setState(
      {
        sortedField,
        sortDirection,
      },
      () => {
        this.fetchUsers();
      }
    );
  };

  render() {
    const {
      userReducer: { data: users, loading, total },
      editUserDetail,
    } = this.props;
    const {
      page,
      searchText,
      isModalOpen,
      editData,
      size,
      sortedField,
      sortDirection,
    } = this.state;
    const showMsg = this.showMessage();

    return (
      <div className="users-container">
        {showMsg}
        <div className="content-block">
          <div className="user-body">
            <Form.Field
              id="search"
              control={Input}
              name="searchText"
              className="search"
              onChange={this.handleSearchTextChange}
              value={searchText}
              placeholder="Enter search text and press enter"
              style={{ width: "100%" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) this.handleSearchClick();
              }}
            />
            <Button primary size="medium" onClick={this.handleSearchClick}>
              Search
            </Button>
          </div>
        </div>
        <div className="page-content">
          <div className="user-pagination">
            <PaginationComponent
              totalPages={Math.ceil(total / size)}
              activePage={page}
              handlePageChange={this.handlePageChange}
            />
            <span className="records-count">{total} User found !</span>
          </div>
          <div className="pagination">
            <span>Records per page : </span>
            <Select
              placeholder="Records Per Page"
              options={[
                { value: 10, text: "10" },
                { value: 20, text: "20" },
                { value: 50, text: "50" },
                { value: 100, text: "100" },
              ]}
              onChange={this.handleChange}
              defaultValue={10}
              compact
              className="role-dropdown"
            />
          </div>
        </div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">
                <p>Edit</p>
              </Table.HeaderCell>
              <Table.HeaderCell>
                First Name
                <Button
                  className="sort-icon"
                  float="right"
                  icon={
                    sortedField === "fname"
                      ? sortDirection === "asc"
                        ? "sort up"
                        : "sort down"
                      : "sort"
                  }
                  size="tiny"
                  onClick={() => {
                    this.handleSort("fname");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Last Name
                <Button
                  className="sort-icon"
                  float="right"
                  icon={
                    sortedField === "lname"
                      ? sortDirection === "asc"
                        ? "sort up"
                        : "sort down"
                      : "sort"
                  }
                  size="tiny"
                  onClick={() => {
                    this.handleSort("lname");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                User Name
                <Button
                  className="sort-icon"
                  float="right"
                  icon={
                    sortedField === "username"
                      ? sortDirection === "asc"
                        ? "sort up"
                        : "sort down"
                      : "sort"
                  }
                  size="tiny"
                  onClick={() => {
                    this.handleSort("username");
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                Role
                <Button
                  className="sort-icon"
                  float="right"
                  icon={
                    sortedField === "role"
                      ? sortDirection === "asc"
                        ? "sort up"
                        : "sort down"
                      : "sort"
                  }
                  size="tiny"
                  onClick={() => {
                    this.handleSort("role");
                  }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan="6">
                  <Loader active inline="centered" />
                </Table.Cell>
              </Table.Row>
            ) : users.length ? (
              users.map((user) => (
                <Table.Row key={user.id}>
                  <Table.Cell textAlign="center">
                    <div className="action-check">
                      <Label
                        className="label-text"
                        onClick={() => {
                          this.handleEdit(user);
                        }}
                      >
                        <Icon name="edit" style={{ color: "#348ecd" }} />
                      </Label>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{user.fname || "-"}</Table.Cell>
                  <Table.Cell>{user.lname || "-"}</Table.Cell>
                  <Table.Cell>{user.username || "-"}</Table.Cell>
                  <Table.Cell>{user.role || "-"}</Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan="5">
                  No Records Found
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <PaginationComponent
          totalPages={Math.ceil(total / size)}
          activePage={page}
          handlePageChange={this.handlePageChange}
        />
        <MyModal
          open={isModalOpen}
          title={"Update User Detail"}
          onClose={() => this.setState({ isModalOpen: false, editData: {} })}
        >
          <EditUserPage
            open={isModalOpen}
            closeModal={() =>
              this.setState({
                isModalOpen: false,
                editData: {},
              })
            }
            editUserDetail={editUserDetail}
            eData={editData}
            showStatus={this.showStatus}
          />
        </MyModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userReducer: state.userReducer,
  authReducer: state.authReducer,
});

export default connect(mapStateToProps, {
  fetchUsersAction: getAllUsers,
  editUserDetail: editUser,
})(Users);

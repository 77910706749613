import React from "react";
import {Form} from "semantic-ui-react";

import CustomModal from "../CustomModal";
import Snackbar from "../Snackbar";

class AddFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        name: '',
        permission: 4,
      },
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    }
  }

  handleState = (e) => {
    let {name, value} = e.target;
    let {payload} = this.state;
    if(name === 'permission') value = Number(value);
    this.setState({
      payload: {
        ...payload,
        [name]: value
      }
    });
  };

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  onSubmit = () => {
    const {addFolder, parent, onClose} = this.props;
    const {payload} = this.state;
    addFolder({...payload, parent}).then(() => {
      this.setState({
        payload: {
          name: '',
          permission: 4,
        },
        snackbar: {
          open: true,
          message: 'Folder Added',
          success: true,
          error: false
        }
      });
      onClose();
    }).catch((error) => {
      this.setState({
        payload: {
          name: '',
          permission: 4,
        },
        snackbar: {
          open: true,
          message: error.data && error.data.message,
          success: false,
          error: true
        }
      });
      onClose();
    });
  }

  render() {
    const {payload: {name, permission}, snackbar} = this.state;
    const {open, onClose, folderLoading} = this.props;
    return <>
      <CustomModal
        open={open}
        onClose={onClose}
        footer={true}
        title='Add Folder'
        onSubmit={this.onSubmit}
        submitLoader={folderLoading}
      >
        <Form>
          <Form.Field>
            <label>Name</label>
            <Form.Input
              fluid
              id="folder-name"
              value={name}
              onChange={this.handleState}
              name="name"
              placeholder="Folder Name"
            />
          </Form.Field>
          <Form.Field
            label='Permission'
            name='permission'
            control='select'
            value={permission}
            onChange={this.handleState}
            width={6}
          >
            <option value='1'>Private</option>
            <option value='2'>Logged-in</option>
            <option value='4'>Public</option>
          </Form.Field>
        </Form>
      </CustomModal>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
        success={snackbar.success}
        onClose={this.resetSnackbar}
      />
    </>
  }
}

export default AddFolder;

import React from 'react';
import {Message, Transition, Icon} from 'semantic-ui-react';
import './Snackbar.css';

export default function Snackbar(props) {

  const handleComplete = () => {
    const {onClose} = props;
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  const {
    message, success, error, info, warning, open
  } = props;

  return <div style={{position: 'fixed', bottom: '5%', width: '100%', zIndex: '9999'}}>
    <Transition
      transitionOnMount
      unmountOnHide
      animation="slide up"
      duration="100"
      visible={open}
      onComplete={handleComplete}
    >
      <div className="full-width">
        <Message
          className={`message-background custom-margin-auto ${success && 'success-class'} ${error && 'error-class'} ${info && 'info-class'} ${warning && 'warning-class'}`}>
          <div>
            {success && <Icon name="check circle outline"/>}
            {error && <Icon name="exclamation circle"/>}
            {info && <Icon name="info circle"/>}
            {warning && <Icon name="exclamation triangle"/>}
            <span>
              {message}
            </span>
          </div>
        </Message>
      </div>
    </Transition>
  </div>
};

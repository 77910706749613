import React from 'react';
import {Route, Switch} from 'react-router-dom';

import CustomRoute from "./components/CustomRoute";
import Login from './components/Login';
import Logout from "./components/Logout";
import Users from "./components/User";
import PublicMedia from "./components/PublicMedia";
import OnSiteMedia from "./components/OnSiteMedia";
import HomeContainer from "./containers/HomeContainer";
import YoutubeTrimmer from './components/YoutubeTrimmer';
import SharedWithMe from './components/SharedWithMe';
import WeeklySubmission from './components/WeeklySubmission';

import './App.css';

function App() {
  return (
    <div>
      <Switch>
        <Route exact path='/' component={Login}/>
        <Route exact path='/logout' component={Logout}/>
        <Route exact path='/media' component={PublicMedia}/>
        <CustomRoute exact allowedUsers={['admin', 'user']} path="/home" component={HomeContainer} />
        <CustomRoute exact allowedUsers={['admin']} path="/users" component={Users} />
        <CustomRoute exact allowedUsers={['admin']} path="/weekly-submissions" component={WeeklySubmission} />
        <CustomRoute exact allowedUsers={['admin', 'user']} path="/youtubeDl" component={YoutubeTrimmer} />
        <CustomRoute exact allowedUsers={['admin', 'user']} path="/onsite" component={OnSiteMedia} />
        <CustomRoute exact allowedUsers={['admin', 'user']} path="/shared-with-me" component={SharedWithMe} />
        <Route
          render={
            () => <div align="center"><h2> 404 Not found </h2></div>
          }
        />
      </Switch>
    </div>
  );
}

export default App;

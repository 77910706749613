import React from "react";
import {Form, Progress, Icon} from "semantic-ui-react";

import CustomModal from "../CustomModal";
import Snackbar from "../Snackbar";
import baseService from '../../services/baseService';

class FolderUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        folderName: null,
        folderPermission: 4,
        files: [],
      },
      fileUploadLength: 0,
      fileUploading: [],
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false,
      }
    }
  }

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  changePermission = (e) => {
    const {value} = e.target;
    const {payload} = this.state;
    this.setState({
      payload: {
        ...payload,
        folderPermission : Number(value)
      }
    });
  };

  folderChange = (event) => {
    const eventFiles = event.target.files;
    if (eventFiles && eventFiles.length > 0) {
      let files = [];
      let folderName = eventFiles[0].webkitRelativePath.split('/')[0];
      for(let file of eventFiles) {
        if((file.size / 1024 / 1024) > 500) {
          this.setState({
            snackbar: {
              open: true,
              message: 'All file size in the folder must be 500 MB or less!',
              success: false,
              error: true
            }
          });
          return this.props.onClose();
        } else {
          files.push(file);
        }
      }
      this.setState(state => ({payload: {...state.payload, files, folderName}, fileUploadLength: files.length}));
    }
  }

  uploadFile = (file, folderID, index) => new Promise(resolve => {
    const formData = new FormData();
    formData.append('files', file);
    let {fileUploading} = this.state;
    fileUploading.push({name: file.name, percentage: 0, status: 'uploading'});
    this.setState({fileUploading});
    baseService.post('/file/upload', formData, {
      onUploadProgress: (progressEvent) => {
        let {fileUploading} = this.state;
        fileUploading[index].percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total) - 5;
        this.setState({fileUploading});
      },
    })
      .then((response) => {
        let dataToUpload = {
          access_url: response.data.access_url,
          thumbnail: response.data.thumbnail,
          folder_id: folderID,
          name: file.name,
          type: file.type,
        }
        const {addFile} = this.props;
        addFile(dataToUpload).then(() => {
          let {fileUploading} = this.state;
          fileUploading[index].percentage = 100;
          fileUploading[index].status = 'success';
          this.setState({fileUploading});
        })
        .catch(() => {
          let {fileUploading} = this.state;
          fileUploading[index].status = 'failed';
          this.setState({fileUploading});
        })
        .finally(() => resolve())
      })
      .catch(() => {
        let {fileUploading} = this.state;
        fileUploading[index].status = 'failed';
        this.setState({fileUploading});
        return resolve();
      });
  })

  onSubmit = () => {
    const {addFolder, parent, onClose} = this.props;
    const {payload} = this.state;
    if(!payload.folderName) {
      this.setState({
        payload: {
          folderName: null,
          folderPermission: 4,
          files: [],
        },
        fileUploadLength: 0,
        fileUploading: [],
        snackbar: {
          open: true,
          message: 'Please select a folder!',
          success: false,
          error: true
        }
      });
      return onClose();
    }
    let folderData = {
      name: payload.folderName,
      permission: payload.folderPermission,
      parent,
    }
    addFolder(folderData).then(async folderID => {
      for(let i = 0, len = payload.files.length; i < len; i++) {
        await this.uploadFile(payload.files[i], folderID, i);
      }
      this.setState({
        payload: {
          folderName: null,
          folderPermission: 4,
          files: [],
        },
        fileUploadLength: 0,
        fileUploading: [],
        snackbar: {
          open: true,
          message: 'Folder Uploaded!',
          success: true,
          error: false
        },
      });
    }).catch((error) => {
      this.setState({
        payload: {
          folderName: null,
          folderPermission: 4,
          files: [],
        },
        fileUploadLength: 0,
        fileUploading: [],
        snackbar: {
          open: true,
          message: error.data && error.data.message,
          success: false,
          error: true
        },
      });
    }).finally(() => onClose());
  }

  render() {
    const {open, onClose, fileLoading} = this.props;
    const { snackbar, payload: {folderPermission, folderName}, fileUploading, fileUploadLength } = this.state;
    let fileUploadingLength = fileUploading.length;
    let lastFileUpload = fileUploading[fileUploadingLength - 1];
    let failCount = fileUploading.filter(x => x.status === 'failed').length;
    return <>
      <CustomModal
        open={open}
        onClose={() => {
          if(fileUploadingLength === 0 || fileUploading.filter(x => x.status === 'uploading').length === 0) {
            this.setState({
              payload: {
                folderName: null,
                folderPermission: 4,
                files: [],
              },
              fileUploadLength: 0,
              fileUploading: []
            });
            onClose();
          }
        }}
        footer={true}
        title='Folder Upload'
        onSubmit={this.onSubmit}
        submitLoader={fileLoading}
      >
        <Form>
          <Form.Group>
            <Form.Field>
              <label>Folder</label>
              <label htmlFor='input-folder-upload'>
                <Icon name='folder outline' size='large' bordered title='select folder' style={{cursor: 'pointer'}}/>
                {
                  folderName || ''
                }
              </label>
              <input
                type='file'
                id='input-folder-upload'
                webkitdirectory=''
                style={{visibility: 'hidden', position: 'absolute'}}
                onChange={this.folderChange}
              />
            </Form.Field>
            <Form.Field
              label='Permission'
              name='folderPermission'
              control='select'
              value={folderPermission}
              onChange={this.changePermission}
              width={6}
            >
              <option value='1'>Private</option>
              <option value='2'>Logged-in</option>
              <option value='4'>Public</option>
            </Form.Field>
          </Form.Group>
          {
            fileUploadingLength > 0 &&
            <><label>Uploading : {fileUploadingLength}/{fileUploadLength} Failed : {failCount}</label>
            <br/><label>{lastFileUpload.name}</label>
            <Progress percent={lastFileUpload.percentage} success={lastFileUpload.status==='success'} indicating progress /></>
          }
        </Form>
      </CustomModal>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
        success={snackbar.success}
        onClose={this.resetSnackbar}
      />
    </>
  }
}

export default FolderUpload;

import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';
import initialState from "./reducers/initialState";

const middleware = [
  thunk
];

const enhancer = [];

if(process.env.REACT_APP_ENV === 'development' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
  enhancer.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const composeEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancer
);

export default createStore(rootReducer, {...initialState}, composeEnhancers);

import React from "react";
import {Accordion, Dropdown, Icon} from "semantic-ui-react";

import './index.css'

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeIndex: [0]
    }
  }

  componentDidMount() {
    this.fillSideBar();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.fillSideBar();
  }

  fillSideBar = () => {
    const {folders: folderData} = this.props;
    let root = folderData.filter(x => !x.parent);
    this.attachChild(root, folderData);
    this.setState({data: root});
  }

  attachChild = (root, folderData) => {
    root.filter(x => x.hasOwnProperty('parent')).map(x => {
      x.children = folderData.filter(y => y.parent === x.id);
      if (x.children.length) {
        this.attachChild(x.children, folderData);
      }
    });
  }

  getHomePanel = () => {
    const {data, activeIndex} = this.state;
    const {selectedFolder} = this.props;
    let path = [{folder_id: null, name: 'Home'}];
    let panelData = this.getPanels(data, path);
    return <>
      <Accordion.Title
        active={activeIndex.includes(0)}
        index={0}
        className={!selectedFolder ? 'selected' : ''}
        onClick={(e) => {
          if(e.ctrlKey)
            this.changeFolder(path, null, 'Home')
          else
            this.handleClick(0)
        }}
        onDoubleClick={() => {
          this.changeFolder(path, null, 'Home')
        }}
      >
        <Icon name='dropdown'/>
        <Icon name='home'/>
        <span className="unselectable">Home</span>
      </Accordion.Title>
      <Accordion.Content active={activeIndex.includes(0)}>
        {
          <Accordion.Accordion panels={panelData}/>
        }
      </Accordion.Content>
    </>
  }

  handleClick = (index) => {
    const {activeIndex} = this.state
    let newIndex;
    if (activeIndex.includes(index)) {
      newIndex = activeIndex.filter(i => i !== index);
    } else {
      newIndex = [...activeIndex, index];
    }

    this.setState({activeIndex: newIndex})
  }

  getPanels = (data, path) => {
    const {selectedFolder} = this.props;
    const {activeIndex} = this.state;

    return data.filter(x => x.hasOwnProperty('children')).map(x =>
      <>
        <Accordion.Title
          active={activeIndex.includes(x.id)}
          index={x.id}
          key={x.id}
          className={x.id === selectedFolder ? 'selected' : ''}
          onClick={(e) => {
            if(e.ctrlKey)
              this.changeFolder([...path, {folder_id: x.id, name: x.name}], x.id, x.name)
            else
              this.handleClick(x.id)
          }}
          onDoubleClick={() => {
            this.changeFolder([...path, {folder_id: x.id, name: x.name}], x.id, x.name)
          }}
        >
          <Icon name='dropdown'/>
          <Icon name={activeIndex.includes(x.id) ? 'folder open' : 'folder'}/>
          <span className="unselectable">{x.name}</span>
        </Accordion.Title>
        <Accordion.Content active={activeIndex.includes(x.id)}>
          {
            x.children.filter(child => child.hasOwnProperty('children')).length > 0 ?
              <Accordion.Accordion key={x.id} panels={this.getPanels(x.children, [...path, {folder_id: x.id, name: x.name}])}/>
              : ''
          }
        </Accordion.Content>
      </>
    );
  }

  changeFolder = (path, folder_id, name) => {
    const {setPathAndFolder} = this.props;
    setPathAndFolder(path, {folder_id, name});
  }

  render() {
    const {addNewFolder, addNewFile, addLanderImage, uploadNewFolder} = this.props;
    return <>
      <Dropdown className="btn-dropdown" icon trigger={<span><Icon name="plus" color="blue"></Icon> New</span>}>
        <Dropdown.Menu>
          <Dropdown.Item icon="folder" text='New Folder' onClick={() => addNewFolder()}/>
          <Dropdown.Item icon="file" text='File Upload' onClick={() => addNewFile()}/>
          <Dropdown.Item icon="folder" text='Folder Upload' onClick={() => uploadNewFolder()}/>
          <Dropdown.Item icon="images" text='Upload Lander Image' onClick={() => addLanderImage()}/>
        </Dropdown.Menu>
      </Dropdown>
      <Accordion styled className="custom-accordion">
        {
          this.getHomePanel()
        }
      </Accordion>
    </>
  }
}

export default SideBarMenu;

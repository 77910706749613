import initialState from './initialState';
import moment from "moment";

export const FILE_LOADING = 'FILE_LOADING';
export const ADD_FILE = 'ADD_FILE';
export const MOVE_FILE = 'MOVE_FILE';
export const EDIT_FILE_NAME = 'EDIT_FILE_NAME';
export const EDIT_VISIBILITY = 'EDIT_VISIBILITY';
export const GET_FILE = 'GET_FILE';
export const GET_PUBLIC_FILE = 'GET_PUBLIC_FILE';
export const GET_ONSITE_FILE = 'GET_ONSITE_FILE';
export const GET_SHARED_FILE = 'GET_SHARED_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_IN_FOLDER = 'DELETE_FILE_IN_FOLDER';

export default function fileReducer(state = initialState.fileReducer, action) {
  switch (action.type) {
    case FILE_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_FILE:
      return {
        ...state,
        data: [...state.data, {...action.payload}]
      };
    case GET_FILE:
      return  {
        ...state,
        data: action.payload
      }
    case GET_PUBLIC_FILE:
      return  {
        ...state,
        publicData: action.payload
      }
    case GET_ONSITE_FILE:
      return  {
        ...state,
        onSiteData: action.payload
      }
    case GET_SHARED_FILE:
      return  {
        ...state,
        sharedData: action.payload
      }
    case MOVE_FILE: {
      let { id, folder_id } = action.payload;
      let index = state.data.findIndex(x => x.id === id);
      let data = [...state.data];
      let file = {...data[index], folder_id, modified: moment()};
      data.splice(index, 1, file);
      return  {
        ...state,
        data
      };
    }
    case EDIT_FILE_NAME: {
      let { id, name } = action.payload;
      let index = state.data.findIndex(x => x.id === id);
      let data = [...state.data];
      let file = {...data[index], name, modified: moment()};
      data.splice(index, 1, file);
      return  {
        ...state,
        data
      };
    }
    case EDIT_VISIBILITY: {
      let { id, is_public } = action.payload;
      let index = state.data.findIndex(x => x.id === id);
      let data = [...state.data];
      let file = {...data[index], is_public, modified: moment()};
      data.splice(index, 1, file);
      return  {
        ...state,
        data
      };
    }
    case DELETE_FILE: {
      let { id } = action.payload;
      let index = state.data.findIndex(x => x.id === id);
      let data = [...state.data];
      data.splice(index, 1);
      return  {
        ...state,
        data
      };
    }
    case DELETE_FILE_IN_FOLDER: {
      let { folders = [] } = action.payload;
      let data = [...state.data.filter(x => !folders.includes(x.folder_id))];
      return  {
        ...state,
        data
      };
    }
    default:
      return state;
  }
}

import initialState from "./initialState";

export const GET_USER = "GET_USER";
export const EDIT_USER = "EDIT_USER";
export const USER_LOADING = "USER_LOADING";
export const LIST_USER = "LIST_USER";

export default function userReducer(state = initialState.userReducer, action) {
  switch (action.type) {
    case USER_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case GET_USER:
      return {
        ...state,
        data: action.payload.users,
        total: action.payload.total,
      };
    case LIST_USER:
      return {
        ...state,
        data: action.payload,
      };
    case EDIT_USER: {
      const index = state.data.findIndex(
        (user) => user.id === action.payload.id
      );
      const data = [...state.data];
      data.splice(index, 1, action.payload);
      return {
        ...state,
        data,
      };
    }
    default:
      return state;
  }
}

import initialState from "./initialState";

export const ELEMENT_LOADING = "ELEMENT_LOADING";
export const GET_ELEMENTS = "GET_ELEMENTS";
export const DOWNLOAD_ELEMENT = "DOWNLOAD_ELEMENT";
export const GET_PUBLIC_ELEMENTS = "GET_PUBLIC_ELEMENTS";
export const EDIT_ELEMENT = "EDIT_ELEMENT";

export default function youtubeDlReducer(
  state = initialState.youtubeDlReducer,
  action
) {
  switch (action.type) {
    case ELEMENT_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case GET_ELEMENTS:
      return {
        ...state,
        data: action.payload.data.map((x) => ({ ...x, downloading: false })),
        total: action.payload.total,
      };
    case DOWNLOAD_ELEMENT:
      const index = state.data.findIndex((e) => e.id === action.payload.id);
      const data = [...state.data];
      data.splice(index, 1, {
        ...data[index],
        downloading: action.payload.loading,
      });
      return {
        ...state,
        data,
      };
    case GET_PUBLIC_ELEMENTS:
      return {
        ...state,
        publicData: action.payload,
      };
    case EDIT_ELEMENT: {
      const index = state.data.findIndex(
        (element) => element.id === action.payload.id
      );
      const data = [...state.data];
      data.splice(index, 1, action.payload);
      return {
        ...state,
        data,
      };
    }
    default:
      return state;
  }
}

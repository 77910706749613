import initialState from './initialState';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export default function authReducer(state = initialState.authReducer, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true
      };
    }
    case LOGIN_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        message: action.payload.message
      };

    default:
      return state;
  }
}

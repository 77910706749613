import React from "react";
import {
  Divider,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Popup,
  Input,
  Dropdown,
  Button,
  Header,
} from "semantic-ui-react";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { getWeeklySubmissions } from "../../services/fileService";
import { downloadFile, getIconBasedOnFile } from "../../utility";
import DownloadAs from "../DownloadAs";
import Snackbar from "../Snackbar";

import "../../containers/home.css";

class WeeklySubmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dateFrom: moment().startOf('week').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
      data: [],
      totalImages: 0,
      totalVideos: 0,
      selectedEditor: 'all',
      editors: [
        {text: 'All Editor', value: 'all', submission: 0},
        {text: 'Hossen', value: 'hossen', submission: 0},
        {text: 'Jon', value: 'jonb', submission: 0},
        {text: 'Mike', value: 'MikeYut', submission: 0},
        {text: 'NAP', value: 'napjr', submission: 0},
        {text: 'Sherwin', value: 'sherwin', submission: 0},
      ],
      openDownloadAs: false,
      fileName: null,
      downloadURL: null,
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    let {dateFrom, dateTo, selectedEditor} = this.state;
    this.setState({loading: true});
    getWeeklySubmissions(dateFrom, dateTo, selectedEditor)
    .then(result => {
      let totalImages = 0, totalVideos = 0, countByOwner = {};
      let {editors} = this.state;
      for(let {name, type, username} of result.data) {
        if(type.includes("image")) {
          totalImages += 1;
        } else if(type.includes("video") || name.includes(".mov")) {
          totalVideos += 1;
        }
        countByOwner[username] = (countByOwner[username] || 0) + 1;
      }
      editors = editors.map(x => ({...x, submission: countByOwner[x.value] || 0}));
      this.setState({loading: false, data: result.data, totalImages, totalVideos, editors});
    })
    .catch(err => this.setState({loading: false}));
  }

  handleDateChange = (e, picker) => {
    let dateFrom = picker.startDate.format('YYYY-MM-DD');
    let dateTo = picker.endDate.format('YYYY-MM-DD');
    this.setState(({dateFrom, dateTo}), () => { this.getData() });
  }

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  render() {
    let {
      loading, data, dateFrom, dateTo, editors, selectedEditor, openDownloadAs, fileName, downloadURL, snackbar, totalImages, totalVideos
    } = this.state;
    return (
      <>
        <Grid className="home-container">
          <GridColumn
            className="main-col"
            mobile={16}
            tablet={12}
            computer={16}
          >
            <div className="main-details" style={{ height: "100%" }}>
              <center>
                <h1>Weekly submissions</h1>
              </center>
              <DateRangePicker
                startDate={dateFrom ? moment(dateFrom) : moment()}
                endDate={dateTo ? moment(dateTo) : moment()}
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "This Week": [moment().startOf("week"), moment()],
                  "Last Week": [
                    moment().subtract(1, "week").startOf("week"),
                    moment().subtract(1, "week").endOf("week"),
                  ],
                  "This Month": [moment().startOf("month"), moment()],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                }}
                alwaysShowCalendars
                onApply={this.handleDateChange}
                linkedCalendars={false}
              >
                <Input
                  value={
                    dateFrom &&
                    `${moment(dateFrom).format("MMM  Do  YYYY")} - ${moment(
                      dateTo
                    ).format("MMM  Do  YYYY")}`
                  }
                  icon="caret down"
                  size="small"
                  name="date"
                  placeholder="Date Range"
                  style={{ width: "250px" }}
                />
              </DateRangePicker>
              <Dropdown
                style={{ marginLeft: "15px" }}
                selection
                name="editor"
                value={selectedEditor}
                options={editors}
                onChange={(e, data) => {
                  this.setState({ selectedEditor: data.value }, () =>
                    this.getData()
                  );
                }}
              />
              <Divider />
              <div className="weekly-stat">
                <h2 className="header">
                  Files<span> ({data.length})</span>
                </h2>
                <div>
                  <Header sub>Videos</Header>
                  <span>{totalVideos}</span>
                </div>
                <div>
                  <Header sub>Images</Header>
                  <span>{totalImages}</span>
                </div>
                {
                  editors.filter(e => e.value !== 'all' && e.submission > 0).map(e => (
                    <div>
                      <Header sub>{e.text}</Header><span>{e.submission}</span>
                    </div>
                  ))
                }
              </div>
              <Grid className="w-100 m-0" columns={3}>
                <GridRow>
                  {loading ? (
                    <div className="d-flex w-100 justify-content-center text-light">
                      Loading...
                    </div>
                  ) : data.length > 0 ? (
                    data.map((f) => (
                      <GridColumn
                        mobile={16}
                        tablet={5}
                        computer={3}
                        key={f.id}
                      >
                        <Button
                          className="file-btn w-100"
                          basic
                          onDoubleClick={() =>
                            window.open(f.access_url, "_blank")
                          }
                        >
                          <div>
                            <div className="file-img">
                              {
                                f.thumbnail !== null
                                 ? <img src={`https://d2a39xd6ecmbf2.cloudfront.net/drive/thumbnail/${f.thumbnail}`} height='150' width='250'/>
                                 : <Icon name={getIconBasedOnFile(f.name)}/>
                              }
                            </div>
                            <div className="d-flex text-truncate file-name">
                              <Popup
                                trigger={
                                  <span className="text-truncate text-left">
                                    {f.name}
                                  </span>
                                }
                                inverted
                                hideOnScroll
                              >
                                <div>Name: {f.name}</div>
                                <div>Owner: {f.username}</div>
                                <div>
                                  Created At:{" "}
                                  {moment(f.created).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </div>
                              </Popup>
                              <div className="folder-action">
                                <Dropdown
                                  key={`dropdown-${f.id}`}
                                  simple
                                  closeOnBlur
                                  closeOnEscape
                                  closeOnChange
                                  direction="left"
                                  icon={
                                    <Icon
                                      size="small"
                                      name="ellipsis vertical"
                                    />
                                  }
                                >
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        window.open(f.access_url, "_blank")
                                      }
                                    >
                                      <Icon name="arrow up" color="blue" />
                                      Open
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          f.access_url
                                        );
                                        this.setState({
                                          snackbar: {
                                            open: true,
                                            message: "Copied to clipboard!",
                                            success: true,
                                            error: false,
                                          },
                                        });
                                      }}
                                    >
                                      <Icon name="copy outline" color="blue" />
                                      Copy Link
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        downloadFile(f.access_url, f.name)
                                      }
                                    >
                                      <Icon name="download" color="blue" />
                                      Download
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.setState({
                                          openDownloadAs: true,
                                          fileName: f.name,
                                          downloadURL: f.access_url,
                                        })
                                      }
                                    >
                                      <Icon name="download" />
                                      Download As
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </Button>
                      </GridColumn>
                    ))
                  ) : (
                    <div className="d-flex w-100 justify-content-center text-light">
                      No Files
                    </div>
                  )}
                </GridRow>
              </Grid>
            </div>
          </GridColumn>
        </Grid>
        <DownloadAs
          open={openDownloadAs}
          onClose={() =>
            this.setState({
              openDownloadAs: false,
              fileName: null,
              downloadURL: null,
            })
          }
          fileName={fileName}
          fileURL={downloadURL}
        />
        <Snackbar
          open={snackbar.open}
          message={snackbar.message}
          error={snackbar.error}
          success={snackbar.success}
          onClose={this.resetSnackbar}
        />
      </>
    );
  }
}

export default WeeklySubmission;

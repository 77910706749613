import moment from "moment";
import * as folderService from '../services/folderService';
import {
  FOLDER_LOADING,  ADD_FOLDER, MOVE_FOLDER, GET_FOLDER, EDIT_FOLDER_NAME, DELETE_FOLDER,
  EDIT_FOLDER_PERMISSION, GET_PUBLIC_FOLDER, GET_ONSITE_FOLDER, GET_SHARED_FOLDER,
} from '../reducers/folderReducer';
import { DELETE_FILE_IN_FOLDER } from '../reducers/fileReducer';

export const getFolder = () => (dispatch) => {
  dispatch({type: FOLDER_LOADING, payload: true});
  folderService.getFolder()
    .then(result => {
      dispatch({type: GET_FOLDER, payload: result.data});
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
}

export const getPublicFolder = (folderID) => (dispatch) => {
  dispatch({type: FOLDER_LOADING, payload: true});
  folderService.getPublicFolder(folderID)
    .then(result => {
      dispatch({type: GET_PUBLIC_FOLDER, payload: result.data});
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
}

export const getOnSiteFolder = (folderID) => (dispatch) => {
  dispatch({type: FOLDER_LOADING, payload: true});
  folderService.getOnSiteFolder(folderID)
    .then(result => {
      dispatch({type: GET_ONSITE_FOLDER, payload: result.data});
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
}

export const getSharedFolder = (folderID) => (dispatch) => {
  dispatch({type: FOLDER_LOADING, payload: true});
  folderService.getSharedFolder(folderID)
    .then(result => {
      dispatch({type: GET_SHARED_FOLDER, payload: result.data});
    })
    .catch((error) => console.log(error))
    .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
}

export const insertFolder = (payload) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FOLDER_LOADING, payload: true});
  folderService.addFolder(payload)
    .then(result => {
      const {insertId: id} = result.data;
      dispatch({type: ADD_FOLDER, payload: {...payload, id, created: moment().format()}});
      resolve(id);
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
});

export const moveFolder = (id, parent) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FOLDER_LOADING, payload: true});
  folderService.moveFolder(id, parent)
    .then(result => {
      dispatch({type: MOVE_FOLDER, payload: { id, parent }});
      resolve();
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
});

export const editFolderName = (id, name) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FOLDER_LOADING, payload: true});
  folderService.editFolderName(id, name)
    .then(result => {
      dispatch({type: EDIT_FOLDER_NAME, payload: { id, name }});
      resolve();
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
});

export const editFolderPermission = (id, permission) => (dispatch) => new Promise((resolve, reject) => {
    dispatch({type: FOLDER_LOADING, payload: true});
    folderService.editFolderPermission(id, permission)
        .then(result => {
            dispatch({type: EDIT_FOLDER_PERMISSION, payload: { id, permission }});
            resolve();
        })
        .catch((error) => reject(error))
        .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
});

export const deleteFolder = (folders) => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: FOLDER_LOADING, payload: true});
  folderService.removeFolder(folders)
    .then(result => {
      dispatch({type: DELETE_FILE_IN_FOLDER, payload: { folders }});
      dispatch({type: DELETE_FOLDER, payload: { folders }});
      resolve();
    })
    .catch((error) => reject(error))
    .finally(() => dispatch({type: FOLDER_LOADING, payload: false}));
});

export const getIconBasedOnFile = (fileName) => {
  const extension = fileName.split(".").pop();
  switch (extension) {
    case "txt":
    case "rtf":
    case "tex":
      return "file text";
    case "png":
    case "jpg":
    case "bmp":
    case "gif":
    case "jpeg":
    case "svg":
    case "webp":
    case "ico":
      return "file image";
    case "tar":
    case "gz":
    case "zip":
    case "7z":
    case "arj":
    case "deb":
    case "pkg":
    case "rar":
    case "rpm":
    case "z":
      return "file archive";
    case "pdf":
      return "file pdf";
    case "ppt":
    case "pptx":
    case "pps":
    case "odp":
      return "file powerpoint";
    case "xls":
    case "ods":
    case "xlsm":
    case "xlsx ":
      return "file excel";
    case "doc":
    case "docx":
    case "odt":
      return "file word";
    case "mp3":
    case "m4a":
    case "aif":
    case "aiff":
    case "cda":
    case "mid":
    case "mpa":
    case "ogg":
    case "wav":
    case "wma":
    case "wpl":
    case "amr":
    case "au":
      return "file audio";
    case "mp4":
    case "mkv":
    case "3g2":
    case "3gp":
    case "avi":
    case "m4v":
    case "mov":
    case "mpg":
    case "mpeg":
    case "wmv":
    case "webm":
      return "file video";
    case "css":
    case "pl":
    case "js":
    case "html":
    case "htm":
    case "c":
    case "json":
    case "env":
    case "csv":
    case "asp":
    case "jsp":
    case "php":
    case "py":
    case "xhtml":
    case "log":
    case "xml":
    case "sql":
    case "sh":
    case "vb":
      return "file code";
    default:
      return "file";
  }
};

export const downloadFile = (url, filename) => {
  let mediaURL = `https://d2a39xd6ecmbf2.cloudfront.net/drive/${url.split('=')[1]}`;
  fetch(mediaURL, {
    headers: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_ORIGIN,
    },
  }).then(function (t) {
    return t.blob().then((b) => {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    });
  });
};

export const downloadYoutubeFile = (url) =>
  new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        "Access-Control-Allow-Origin": process.env.REACT_APP_ORIGIN,
      },
    })
      .then(function (t) {
        t.blob()
          .then((b) => {
            let filename = url.substr(url.lastIndexOf("/") + 1);
            let a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
            resolve();
          })
          .catch(() => reject());
      })
      .catch(() => reject());
  });

export const getShareFolderLink = (id, permission) => {
  let route = permission === 1 ? "shared-with-me" : permission === 2 ? "onsite" : "media"
  return `${process.env.REACT_APP_ORIGIN}/${route}?folder=${id}`;
}

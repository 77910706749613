import React from 'react';
import {connect} from 'react-redux';
import {Button, Breadcrumb, GridColumn, Grid, Icon, GridRow, Dropdown, Divider, Popup} from "semantic-ui-react";
import moment from 'moment';

import './home.css';
import AddFolder from '../components/AddFolder';
import AddFile from '../components/AddFile';
import FolderUpload from '../components/FolderUpload';
import EditName from '../components/EditName';
import SideBarMenu from '../components/SideBarMenu';
import MoveFolderOrFileModel from '../components/MoveFolderOrFile';
import DownloadAs from '../components/DownloadAs';
import {ConfirmModal} from "../components/CustomModal";
import Snackbar from "../components/Snackbar";
import ShareFolder from "../components/ShareFolder";

import {
  getFolder,
  moveFolder,
  editFolderName,
  editFolderPermission,
  insertFolder,
  deleteFolder,
} from '../actions/folderActions';
import {getFile, moveFile, editFileName, editVisibility, insertFile, deleteFile} from '../actions/fileActions';
import {listUser} from '../actions/userActions';
import {getIconBasedOnFile, downloadFile, getShareFolderLink} from '../utility/index';
import AddLanderImage from "../components/AddLanderImage";

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddFolder: false,
      openAddFile: false,
      openUploadFolder: false,
      openAddLanderImage: false,
      openMoveFile: false,
      openMoveFolder: false,
      openDeleteFile: false,
      openDeleteFolder: false,
      openDownloadAs: false,
      openShareFolder: false,
      downloadURL: null,
      folderForAction: null,
      fileForAction: null,
      editNamePopup: null,
      editName: null,
      currentFolder: {
        folder_id: null,
        name: 'Home'
      },
      folderPath: [{
        folder_id: null,
        name: 'Home'
      }],
      sideBarMenu: [],
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      },
      shareFolder: null,
    }
  }

  componentDidMount() {
    const {fetchFolder, fetchFile, listSiteUsers} = this.props;
    fetchFolder();
    fetchFile();
    listSiteUsers();
  }

  getFiles = () => {
    let {currentFolder: {folder_id}} = this.state;
    let {file: {data}} = this.props;
    return data.filter(x => x.folder_id === folder_id);
  }

  getFolders = () => {
    let {currentFolder: {folder_id}} = this.state;
    let {folder: {data}} = this.props;
    return data.filter(x => x.parent === folder_id);
  }

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  setPathAndFolder = (folderPath, currentFolder) => {
    this.setState({currentFolder, folderPath});
  }

  handleFolderClick = (folder) => {
    let {id: folder_id, name, permission} = folder;
    this.setState(state => ({
      ...state,
      currentFolder: {folder_id, name, permission},
      folderPath: [...state.folderPath, {folder_id, name, permission}]
    }));
  }

  changeFolder = (folder, index) => {
    this.setState(state => ({
      ...state,
      currentFolder: {...folder},
      folderPath: state.folderPath.filter((_, i) => i <= index)
    }));
  }

  shareFolderClick = (folder) => {
    this.setState({shareFolder: {...folder}, openShareFolder: true });
  }

  shareFolderLink = ({id, permission}) => {
    navigator.clipboard.writeText(getShareFolderLink(id, permission));
    this.setState({
      snackbar: {
        open: true,
        message: 'Copied to clipboard!',
        success: true,
        error: false
      }
    });
  }

  confirmDeleteFile = () => {
    const {removeFile} = this.props;
    const {fileForAction} = this.state;
    removeFile(fileForAction)
      .then(() => {
        this.setState({
          fileForAction: null, editName: null, openDeleteFile: false,
          snackbar: {
            open: true,
            message: 'File Deleted!',
            success: true,
            error: false
          }
        });
      })
      .catch(err => {
        this.setState({
          fileForAction: null, editName: null, openDeleteFile: false,
          snackbar: {
            open: true,
            message: err.data && err.data.message,
            success: false,
            error: true
          }
        })
      });
  }

  getChildFolder = (id) => {
    let folders = [];
    const {folder: {data: folderData = []}} = this.props;
    folderData.filter(x => x.parent === id).forEach(x => {
      folders = [...folders, x.id, ...this.getChildFolder(x.id)];
    })
    return folders;
  }

  confirmDeleteFolder = () => {
    const {removeFolder} = this.props;
    const {folderForAction} = this.state;
    let folders = [folderForAction, ...this.getChildFolder(folderForAction)];
    removeFolder(folders)
      .then(() => {
        this.setState({
          folderForAction: null, editName: null, openDeleteFolder: false,
          snackbar: {
            open: true,
            message: 'Folder Deleted!',
            success: true,
            error: false
          }
        });
      })
      .catch(err => {
        this.setState({
          folderForAction: null, editName: null, openDeleteFolder: false,
          snackbar: {
            open: true,
            message: err.data && err.data.message,
            success: false,
            error: true
          }
        })
      });
  }

  render() {
    const {
      openAddFolder, openAddFile, openMoveFolder, openMoveFile, currentFolder: {folder_id}, folderPath, folderForAction,
      fileForAction, editNamePopup, editName, openDeleteFile, openDeleteFolder, snackbar, openDownloadAs, downloadURL,
      openAddLanderImage, openShareFolder, shareFolder, openUploadFolder,
    } = this.state;
    const {
      folder: {data: folderData, loading: folderLoading},
      file: {loading: fileLoading},
      user: { data: users },
      addFolder,
      moveFolderAction,
      addFile,
      moveFileAction,
      renameFolder,
      editPermission,
      renameFile,
      setVisibility
    } = this.props;
    return <>
      <Grid className="home-container">
        <GridColumn only='mobile' className="p-0">
          <Dropdown className="btn-dropdown"
                    closeOnBlur
                    closeOnEscape
                    closeOnChange
                    icon
                    trigger={<span><Icon name="plus" color="blue"/> New</span>}
          >
            <Dropdown.Menu>
              <Dropdown.Item icon="folder" text='New Folder' onClick={() => this.setState({openAddFolder: true})}/>
              <Dropdown.Item icon="file" text='File Upload' onClick={() => this.setState({openAddFile: true})}/>
              <Dropdown.Item icon="folder" text='Folder Upload' onClick={() => this.setState({openUploadFolder: true})}/>
              <Dropdown.Item icon="images" text='Upload Lander Image' onClick={() => this.setState({openAddLanderImage: true})}/>
            </Dropdown.Menu>
          </Dropdown>
        </GridColumn>
        <GridColumn className="main-col" only='tablet computer' tablet={4} computer={3}>
          <SideBarMenu
            addNewFolder={() => this.setState({openAddFolder: true})}
            addNewFile={() => this.setState({openAddFile: true})}
            uploadNewFolder={() => this.setState({openUploadFolder: true})}
            addLanderImage={() => this.setState({openAddLanderImage: true})}
            folders={folderData}
            selectedFolder={folder_id}
            setPathAndFolder={this.setPathAndFolder}
          />
        </GridColumn>
        <GridColumn className="main-col" mobile={16} tablet={12} computer={13}>
          <Breadcrumb size="huge" className="custom-breadcrumb">
            {
              folderPath.map((f, i) => <>
                <Breadcrumb.Section
                  active={i === folderPath.length - 1}
                  link
                  onClick={() => this.changeFolder(f, i)}
                >
                  <span className="unselectable">{f.name}</span>
                </Breadcrumb.Section>
                {
                  i !== folderPath.length - 1 && <Breadcrumb.Divider icon='right chevron'/>
                }
                {
                  f.name !== 'Home' && i === folderPath.length - 1 &&
                  <Button
                   basic
                   circular
                   icon='share'
                   size='mini'
                   title={`Copy Link of ${f.name} Folder`}
                   onClick={() => this.shareFolderLink({id: f.folder_id, ...f})}
                  />
                }
              </>)
            }
          </Breadcrumb>
          <Divider/>
          <div className="main-details">
            <h2 className="header">Folders</h2>
            <Grid className="w-100 m-0">
              <GridRow>
                {
                  this.getFolders().length > 0
                    ?
                    this.getFolders().map(f =>
                      <GridColumn key={f.id} mobile={16} tablet={5} computer={3}>
                        <div
                          className={`folder-btn w-100 ui basic button ${f.permission === 4 ? 'public' : f.permission === 2 ? 'onsite' : 'private'}`}
                          onClick={
                            (e) => {
                              if (e.ctrlKey) this.handleFolderClick(f)
                            }
                          }
                          onDoubleClick={() => {
                            this.handleFolderClick(f)
                          }}
                        >
                          <div className="d-flex text-truncate">
                            <Icon name='folder'/>
                            <Popup
                              trigger={<span className="text-truncate text-left">{f.name}</span>}
                              inverted
                              hideOnScroll
                            >
                              <div>Name: {f.name}</div>
                              <div>Created At: {moment(f.created).format('YYYY-MM-DD HH:mm:ss')}</div>
                              { f.modified && <div>Last Modified At: {moment(f.modified).format('YYYY-MM-DD HH:mm:ss')}</div> }
                            </Popup>
                            <div className="folder-action">
                              <Dropdown
                                key={`dropdown-${f.id}`}
                                simple
                                closeOnBlur
                                closeOnEscape
                                closeOnChange
                                direction='left'
                                icon={<Icon size="small" name="ellipsis vertical"/>}
                              >
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => this.handleFolderClick(f)}>
                                    <Icon name="arrow up" color="blue"/>
                                    Open
                                  </Dropdown.Item>
                                  <Dropdown
                                    pointing='left'
                                    className='link item'
                                    icon='share'
                                    simple
                                    closeOnBlur
                                    closeOnEscape
                                    closeOnChange
                                  >
                                    <>
                                      Share
                                      <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.shareFolderClick(f)}>
                                          <Icon name="user plus" color="blue"/>
                                          Share With User
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.shareFolderLink(f)}>
                                          <Icon name="copy outline" color="blue"/>
                                          Copy Link
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </>
                                  </Dropdown>
                                  <Dropdown.Item
                                    onClick={
                                      () => this.setState({
                                        editNamePopup: true,
                                        editName: f.name,
                                        folderForAction: f.id
                                      })
                                    }
                                  >
                                    <Icon name="edit" color="blue"/>
                                    Edit Name
                                  </Dropdown.Item>
                                  <Dropdown
                                    pointing='left'
                                    className='link item'
                                    icon='users'
                                    simple
                                    closeOnBlur
                                    closeOnEscape
                                    closeOnChange
                                  >
                                    <>
                                      Permission
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          active={f.permission === 1}
                                          onClick={
                                            () => f.permission !== 1 && editPermission(f.id, 1)
                                          }
                                        >
                                          <Icon name='low vision' color="blue"/>
                                          Private
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          active={f.permission === 2}
                                          onClick={
                                            () => f.permission !== 2 && editPermission(f.id, 2)
                                          }
                                        >
                                          <Icon name='users' color="blue"/>
                                          Logged-in
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          active={f.permission === 4}
                                          onClick={
                                            () => f.permission !== 4 && editPermission(f.id, 4)
                                          }
                                        >
                                          <Icon name='universal access' color="blue"/>
                                          Public
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </>
                                  </Dropdown>
                                  <Dropdown.Item
                                    onClick={() => this.setState({openMoveFolder: true, folderForAction: f.id})}
                                  >
                                    <Icon name="move" color="blue"/> Move Folder
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.setState({folderForAction: f.id, editName: f.name, openDeleteFolder: true})
                                    }
                                  >
                                    <Icon name="trash alternate outline" color="red"/>
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </GridColumn>
                    )
                    : <div className="d-flex w-100 justify-content-center text-light">No Folders</div>
                }
              </GridRow>
            </Grid>
            <h2 className="header">Files</h2>
            <Grid className="w-100 m-0" columns={3}>
              <GridRow>
                {
                  this.getFiles().length > 0
                    ?
                    this.getFiles().map(f =>
                      <GridColumn mobile={16} tablet={5} computer={3} key={f.id}>
                        <Button
                          className={`file-btn w-100 ${f.is_public ? 'public' : 'private'}`}
                          basic
                          onDoubleClick={() => window.open(f.access_url, '_blank')}
                        >
                          <div>
                            <div className="file-img">
                              {
                                f.thumbnail !== null
                                 ? <img src={`https://d2a39xd6ecmbf2.cloudfront.net/drive/thumbnail/${f.thumbnail}`} height='150' width='250'/>
                                 : <Icon name={getIconBasedOnFile(f.name)}/>
                              }
                            </div>
                            <div className="d-flex text-truncate file-name">
                              <Popup
                                trigger={<span className="text-truncate text-left">{f.name}</span>}
                                inverted
                                hideOnScroll
                              >
                                <div>Name: {f.name}</div>
                                <div>Created At: {moment(f.created).format('YYYY-MM-DD HH:mm:ss')}</div>
                                { f.modified && <div>Last Modified At: {moment(f.modified).format('YYYY-MM-DD HH:mm:ss')}</div> }
                              </Popup>
                              <div className="folder-action">
                                <Dropdown
                                  key={`dropdown-${f.id}`}
                                  simple
                                  closeOnBlur
                                  closeOnEscape
                                  closeOnChange
                                  direction='left'
                                  icon={<Icon size="small" name="ellipsis vertical"/>}
                                >
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => window.open(f.access_url, '_blank')}>
                                      <Icon name="arrow up" color="blue"/>
                                      Open
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                      navigator.clipboard.writeText(f.access_url)
                                      this.setState({
                                        snackbar: {
                                          open: true,
                                          message: 'Copied to clipboard!',
                                          success: true,
                                          error: false
                                        }
                                      });
                                    }}>
                                      <Icon name="copy outline" color="blue"/>
                                      Copy Link
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => downloadFile(f.access_url, f.name)}
                                    >
                                      <Icon name="download" color="blue"/>
                                      Download
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.setState({ openDownloadAs: true, editName: f.name, downloadURL: f.access_url })
                                      }
                                    >
                                      <Icon name="download"/>
                                      Download As
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.setState({editNamePopup: true, editName: f.name, fileForAction: f.id})
                                      }
                                    >
                                      <Icon name="edit" color="blue"/>
                                      Edit Name
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={
                                        () => setVisibility(f.id, !f.is_public)
                                      }
                                    >
                                      <Icon name={f.is_public ? 'low vision' : 'universal access'} color="blue"/> Make { f.is_public ? 'Private' : 'Public' }
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={
                                        () => this.setState({openMoveFile: true, editName: f.name, fileForAction: f.id})
                                      }
                                    >
                                      <Icon name="move" color="blue"/> Move File
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.setState({fileForAction: f.id, editName: f.name, openDeleteFile: true})
                                      }
                                    >
                                      <Icon name="trash alternate outline" color="red"/>
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </Button>
                      </GridColumn>
                    )
                    : <div className="d-flex w-100 justify-content-center text-light">No Files</div>
                }
              </GridRow>
            </Grid>
          </div>
        </GridColumn>
      </Grid>
      <AddFolder
        addFolder={addFolder}
        folderLoading={folderLoading}
        open={openAddFolder}
        parent={folder_id}
        onClose={() => {
          this.setState({openAddFolder: false})
        }}
      />
      <AddFile
        open={openAddFile}
        fileLoading={fileLoading}
        addFile={addFile}
        folderID={folder_id}
        onClose={() => {
          this.setState({openAddFile: false})
        }}
      />
      <FolderUpload
        open={openUploadFolder}
        fileLoading={fileLoading}
        addFile={addFile}
        addFolder={addFolder}
        parent={folder_id}
        onClose={() => {
          this.setState({openUploadFolder: false})
        }}
      />
      <AddLanderImage
        open={openAddLanderImage}
        onClose={() => {
          this.setState({openAddLanderImage: false})
        }}
      />
      <MoveFolderOrFileModel
        folders={folderData}
        open={openMoveFolder || openMoveFile}
        onClose={() => {
          this.setState({openMoveFile: false, openMoveFolder: false, fileForAction: null, folderForAction: null})
        }}
        folderLoading={folderLoading}
        fileLoading={fileLoading}
        moveFolderAction={moveFolderAction}
        moveFileAction={moveFileAction}
        fileForAction={fileForAction}
        folderForAction={folderForAction}
      />
      <EditName
        name={editName}
        open={editNamePopup}
        folderLoading={folderLoading}
        fileLoading={fileLoading}
        folderID={folderForAction}
        fileID={fileForAction}
        renameFolder={renameFolder}
        renameFile={renameFile}
        onClose={() => {
          this.setState({fileForAction: null, editName: null, folderForAction: null, editNamePopup: false})
        }}
      />
      <DownloadAs
        open={openDownloadAs}
        onClose={() => this.setState({ openDownloadAs: false, editName: null, downloadURL: null })}
        fileName={editName}
        fileURL={downloadURL}
      />
      <ConfirmModal
        open={openDeleteFolder}
        loading={folderLoading}
        onCancelClick={() => this.setState({folderForAction: null, openDeleteFolder: false, editName: null})}
        onYesClick={this.confirmDeleteFolder}
        header={'Delete Folder'}
        primaryKey={`Folder ${editName}`}
      />
      <ConfirmModal
        open={openDeleteFile}
        loading={fileLoading}
        onCancelClick={() => this.setState({fileForAction: null, openDeleteFile: false, editName: null})}
        onYesClick={this.confirmDeleteFile}
        header={'Delete File'}
        primaryKey={`File ${editName}`}
      />
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
        success={snackbar.success}
        onClose={this.resetSnackbar}
      />
      <ShareFolder
        open={openShareFolder}
        folder={shareFolder}
        users={users}
        onClose={() => this.setState({ openShareFolder: false, shareFolder: null })}
      />
    </>
  }
}

const mapStateToProps = state => ({
  auth: state.authReducer,
  file: state.fileReducer,
  folder: state.folderReducer,
  user: state.userReducer,
});

const mapDispatchToProps = {
  fetchFolder: getFolder,
  addFolder: insertFolder,
  moveFolderAction: moveFolder,
  renameFolder: editFolderName,
  editPermission: editFolderPermission,
  setVisibility: editVisibility,
  removeFolder: deleteFolder,
  fetchFile: getFile,
  addFile: insertFile,
  moveFileAction: moveFile,
  renameFile: editFileName,
  removeFile: deleteFile,
  listSiteUsers: listUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);

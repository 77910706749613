import React from "react";
import {Form, Input} from "semantic-ui-react";

import CustomModal from "../CustomModal";
import Snackbar from "../Snackbar";
import {uploadLanderImage} from "../../services/fileService";

class AddLanderImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      payload: {
        name: '',
        type: '',
        buffer: '',
      },
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    }
  }

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  fileChange = (event) => {
    const {onClose} = this.props;
    const target = event.target;
    if (target.files.length > 0) {
      const file = target.files[0];
      if ((file.size / 1024 / 1024) > 5) {
        this.setState({
          snackbar: {
            open: true,
            message: 'File size must be 5 MB or less!',
            success: false,
            error: true
          }
        });
        return onClose();
      }
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        let name = file.name;
        let type = file.type;
        let buffer = fileReader.result;
        this.setState({payload: {name, type, buffer}});
      };
      fileReader.onerror = () => {
        console.log(fileReader.result);
      }
    }
  }

  onSubmit = () => {
    const {onClose} = this.props;
    const {payload} = this.state;
    if (!payload.name || !payload.type || !payload.buffer) {
      this.setState({
        snackbar: {
          open: true,
          message: 'Please select a file!',
          success: false,
          error: true
        }
      });
      return onClose();
    }
    this.setState({loading: true});
    uploadLanderImage(payload).then(() => {
      this.setState({
        payload: {
          name: '',
          type: '',
          buffer: ''
        },
        snackbar: {
          open: true,
          message: 'Lander Image Uploaded!',
          success: true,
          error: false
        }
      });
    }).catch((error) => {
      this.setState({
        payload: {
          name: '',
          type: '',
          buffer: ''
        },
        snackbar: {
          open: true,
          message: 'Can not upload Lander Image to S3 bucket',
          success: false,
          error: true
        }
      });
    }).finally(() => {
      this.setState({loading: false});
      onClose();
    });
  }

  render() {
    const {open, onClose} = this.props;
    const {snackbar, loading} = this.state;
    return <>
      <CustomModal
        open={open}
        onClose={onClose}
        footer={true}
        title='Upload Lander Image'
        onSubmit={this.onSubmit}
        submitLoader={loading}
      >
        <Form>
          <Form.Field>
            <label>Image</label>
            <Input
              type='file'
              id='file'
              accept="image/*"
              onChange={this.fileChange}
            />
          </Form.Field>
        </Form>
      </CustomModal>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
        success={snackbar.success}
        onClose={this.resetSnackbar}
      />
    </>
  }
}

export default AddLanderImage;

import React from "react";
import {Form} from "semantic-ui-react";

import CustomModal from "../CustomModal";
import Snackbar from "../Snackbar";

class EditName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        name: '',
      },
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if(this.props.name !== nextProps.name) {
      this.setState({ payload: { name: nextProps.name || '' }});
    }
  }

  handleState = (e) => {
    const {name, value} = e.target;
    const {payload} = this.state;
    this.setState({
      payload: {
        ...payload,
        [name]: value
      }
    });
  };

  resetSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        message: '',
        success: false,
        error: false
      }
    })
  }

  onSubmit = () => {
    const {folderID, fileID, renameFolder, renameFile, onClose} = this.props;
    const {payload: { name }} = this.state;
    if (folderID) {
      renameFolder(folderID, name)
        .then(result => {
          this.setState({
            activeIndex: [0],
            selectedFolder: null,
            snackbar: {
              open: true,
              message: 'Folder Name Edited!',
              success: true,
              error: false
            }
          });
        })
        .catch(error => {
          this.setState({
            activeIndex: [0],
            selectedFolder: null,
            snackbar: {
              open: true,
              message: error.data && error.data.message,
              success: false,
              error: true
            }
          });
        }).finally(onClose);
    } else if (fileID) {
      renameFile(fileID, name)
        .then(result => {
          this.setState({
            activeIndex: [0],
            selectedFolder: null,
            snackbar: {
              open: true,
              message: 'File Name Edited!',
              success: true,
              error: false
            }
          });
        })
        .catch(error => {
          this.setState({
            activeIndex: [0],
            selectedFolder: null,
            snackbar: {
              open: true,
              message: error.data && error.data.message,
              success: false,
              error: true
            }
          });
        }).finally(onClose);
    }
  }

  render() {
    const {payload: {name}, snackbar} = this.state;
    const {open, onClose, fileLoading, folderLoading} = this.props;
    return <>
      <CustomModal
        open={open}
        onClose={onClose}
        footer={true}
        title='Edit Name'
        onSubmit={this.onSubmit}
        submitLoader={fileLoading || folderLoading}
      >
        <Form>
          <Form.Field>
            <label>Name</label>
            <Form.Input
              fluid
              id="name"
              value={name}
              onChange={this.handleState}
              name="name"
              placeholder="Name"
            />
          </Form.Field>
        </Form>
      </CustomModal>
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        error={snackbar.error}
        success={snackbar.success}
        onClose={this.resetSnackbar}
      />
    </>
  }
}

export default EditName;

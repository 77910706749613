import React from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';
import baseService from '../../services/baseService'

const Logout = (props) => {
  setTimeout(() => {
    baseService.removeAuthToken();
  }, 1000);
  return (
    <Dimmer active inverted>
      <Loader inverted content="Logging out"/>
    </Dimmer>
  );
};

export default Logout;

import initialState from './initialState';
import moment from 'moment';

export const FOLDER_LOADING = 'FOLDER_LOADING';
export const ADD_FOLDER = 'ADD_FOLDER';
export const MOVE_FOLDER = 'MOVE_FOLDER';
export const EDIT_FOLDER_NAME = 'EDIT_FOLDER_NAME';
export const EDIT_FOLDER_PERMISSION = 'EDIT_FOLDER_PERMISSION';
export const GET_FOLDER = 'GET_FOLDER';
export const GET_PUBLIC_FOLDER = 'GET_PUBLIC_FOLDER';
export const GET_ONSITE_FOLDER = 'GET_ONSITE_FOLDER';
export const GET_SHARED_FOLDER = 'GET_SHARED_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';

export default function folderReducer(state = initialState.folderReducer, action) {
  switch (action.type) {
    case FOLDER_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case ADD_FOLDER:
      return {
        ...state,
        data: [...state.data, {...action.payload, permission: +action.payload.permission}]
      };
    case GET_FOLDER:
      return  {
        ...state,
        data: action.payload
      };
    case GET_PUBLIC_FOLDER:
      return  {
        ...state,
        publicData: action.payload
      };
    case GET_ONSITE_FOLDER:
      return  {
        ...state,
        onSiteData: action.payload
      };
    case GET_SHARED_FOLDER:
      return  {
        ...state,
        sharedData: action.payload
      };
    case MOVE_FOLDER: {
      let { id, parent } = action.payload;
      let index = state.data.findIndex(x => x.id === id);
      let data = [...state.data];
      let folder = {...data[index], parent, modified: moment()};
      data.splice(index, 1, folder);
      return  {
        ...state,
        data
      };
    }
    case EDIT_FOLDER_NAME: {
      let { id, name } = action.payload;
      let index = state.data.findIndex(x => x.id === id);
      let data = [...state.data];
      let folder = {...data[index], name, modified: moment()};
      data.splice(index, 1, folder);
      return  {
        ...state,
        data
      };
    }
    case EDIT_FOLDER_PERMISSION: {
      let { id, permission } = action.payload;
      let index = state.data.findIndex(x => x.id === id);
      let data = [...state.data];
      let folder = {...data[index], permission, modified: moment()};
      data.splice(index, 1, folder);
      return  {
        ...state,
        data
      };
    }
    case DELETE_FOLDER: {
      let { folders } = action.payload;
      let data = [...state.data];
      data = data.filter(x => !folders.includes(x.id));
      return  {
        ...state,
        data
      };
    }
    default:
      return state;
  }
}

import { combineReducers } from 'redux';

import authReducer from './authReducer';
import fileReducer from './fileReducer';
import folderReducer from './folderReducer';
import userReducer from './userReducer';
import youtubeDlReducer from './youtubeDlReducer';

const reducer = combineReducers({
  authReducer,
  fileReducer,
  folderReducer,
  userReducer,
  youtubeDlReducer
});

const rootReducer = (state, action) => {
  return reducer(state, action);
};

export default rootReducer;

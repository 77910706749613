import cookies from '../cookies';

const initialState = {
  authReducer: {
    isLoggedIn: cookies.get('isLoggedIn') === "true" || false,
    role: cookies.get('role') || '',
    authToken: cookies.get('authToken'),
    firstName: cookies.get('firstName'),
    lastName: cookies.get('lastName'),
    userID: Number(cookies.get('userID'))
  },
  fileReducer: {
    loading: false,
    data: [],
    publicData: [],
    onSiteData: [],
    sharedData: [],
  },
  folderReducer: {
    loading: false,
    data: [],
    publicData: [],
    onSiteData: [],
    sharedData: [],
  },
  userReducer: {
    loading: false,
    data: [],
    total: null
  },
  youtubeDlReducer: {
    loading: false,
    data: [],
    publicData: [],
    total: null
  }
}

export default initialState;
